import React from "react";
import { ASSETMANAGEMENT } from "../../../../constant/constant";
import Card from "../../../../library/custom/cardList/card";
import { Grid } from "@mui/material";
import { checkPrivileges } from "../../../../utils/rolesHelper";
const AssetManagementList = ({ onClick }) => {
  return (
    <>
      <Grid container className="CardListContaner">
        {checkPrivileges([12, 70, 71, 72]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={ASSETMANAGEMENT[0]}
          />
        )}
        {checkPrivileges([12, 78, 79, 80]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={ASSETMANAGEMENT[1]}
          />
        )}
        {checkPrivileges([12, 52, 53, 54]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={ASSETMANAGEMENT[2]}
          />
        )}
        {checkPrivileges([12, 37, 38, 39]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={ASSETMANAGEMENT[3]}
          />
        )}
      </Grid>
    </>
  );
};

export default AssetManagementList;
