import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as userAction from "../../../redux/actions/userAction";
import UserList from "../component/users/userList";
import { useNavigate } from "react-router-dom";
import * as adminAction from "../../../redux/actions/adminCommonAction";

import {
  ADD_ADMIN_GROUP,
  ADD_ADMIN_USER,
} from "../../../constant/routeContant";
import { ORG_ID, PAGE_SIZE } from "../../../constant/constant";

const UsersListContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userListData = useSelector((state) => state.userlistData);

  const NavigateSingleUserMethode = () => {
    navigate(ADD_ADMIN_USER);
  };
  const NavigateUserGroupMethode = () => {
    navigate(ADD_ADMIN_GROUP);
  };

  const getuserLists = (data) => {
    dispatch(userAction.getUserList(data));
  };

  const companies = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.companies
  );

  const jobTitles = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.jobTitles
  );

  const deleteUserListData = useSelector((state) => state.deleteUserListData);

  const deleteLists = (userId) => {
    dispatch(userAction.deleteList(userId));
  };

  const getDropdownDataKeyValueData = { orgId: ORG_ID(), flags: "1,12", lngId: 1 };
  useEffect(() => {
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);

  useEffect(() => {
    const data = {
      page: 1,
      pageSize: PAGE_SIZE,
      search: "",
      orgId: ORG_ID(),
    };
    getuserLists(data);
  }, []);

  useEffect(() => {
    if (deleteUserListData.status === "SUCCESS") {
      const data = {
        page: 1,
        pageSize: PAGE_SIZE,
        search: "",
      };
      getuserLists(data);
    }
  }, [deleteUserListData]);

  return (
    <>
      <UserList
        loading={userListData.isLoading}
        userListData={userListData?.data}
        NavigateSingleUser={NavigateSingleUserMethode}
        NavigateUserGroup={NavigateUserGroupMethode}
        getuserLists={getuserLists}
        deleteList={deleteLists}
        companies={companies}
        jobTitles={jobTitles}
      />
    </>
  );
};

export default UsersListContainer;
