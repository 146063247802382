import React, { useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import "./SearchAutoComplete.scss";
import { useState } from "react";
export default function SearchAutoComplete({
  optionsData,
  onChange,
  value,
  name,
  isError = false,
  errorMsg = "",
  id,
  disabled,
  isUserDetails,
  typeOnchange,
  isKeyValuePair,
  isLoading = false,
  isRegNum = false,
  placeholderText = "",
  noOptionsTextMsg = "No user",
  autoCompClear = null,
}) {
  const [isClicked, setIsClicked] = useState(false);
  const [optionsDatas, setoptionsDatas] = useState([]);
  const [optionsDatass, setoptionsDatass] = useState(null);

  useEffect(() => {
    setoptionsDatas(optionsData);
  }, [optionsData]);
  const onChangehandler = (event, value) => {
    setoptionsDatass(value);
    onChange(event, value);
  };
  useEffect(() => {
    setoptionsDatas([]);
  }, [optionsDatass]);

  return (
    <div className="overideSearchAuto">
      <Autocomplete
        popupIcon={<SearchIcon />}
        disablePortal
        disabled={disabled}
        name={name ? name : "defaultName"}
        id={id}
        value={value ? value : null}
        loading={isLoading}
        options={isClicked ? [] : optionsDatas}
        style={{ padding: "0px", borderRadius: "15px" }}
        size="small"
        noOptionsText={noOptionsTextMsg}
        clearOnEscape={true}
        ref={autoCompClear}
        getOptionLabel={(option) =>
          isUserDetails
            ? `${option.firstName}  ${option?.lastName ? option?.lastName : ""}`
            : isKeyValuePair
            ? ` ${option.value}`
            : isRegNum
            ? option.regNumber
            : option.regNumber
        }
        onChange={(event, value) => onChangehandler(event, value)}
        className="formControlRoot"
        renderInput={(params) => (
          <TextField
            onChange={(event, newInputValue) => {
              if (event.target.value) {
                setIsClicked(false);
              } else {
                setIsClicked(true);
              }
              typeOnchange(event, newInputValue);
            }}
            onClick={(evnt) => setIsClicked(true)}
            error={isError}
            placeholder={placeholderText}
            helperText={errorMsg}
            value={value ? value : null}
            className="formControlRoot"
            style={{ borderRadius: "15px" }}
            InputProps={{
              className: "inputRoot",
            }}
            {...params}
          />
        )}
      />
    </div>
  );
}
