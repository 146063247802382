import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddProject from "../component/project/addProject";
import * as projectAction from "../../../redux/actions/projectAction";
import { ADMIN_PROJECT } from "../../../constant/routeContant";
import SuccessScreen from "../../../library/custom/success/successScreen";
import {
  API_STATUS,
  LNG_ID,
  LOGIN_ORG_ID,
  ORG_ID,
} from "../../../constant/constant";
import * as userAction from "../../../redux/actions/userAction";
import Loader from "../../../library/common/Loader";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import { useLocation } from "react-router-dom";
import { PRODUCT_ID } from "../../../constant/storm-water/constant";

export default function AddProjectContainer() {
  const dispatch = useDispatch();
  const location = useLocation();
  const createProject = useSelector((state) => state.createProject);

  const statuss = createProject?.status;
  const projectId = createProject?.data?.id;
  const [successScreens, setSuccessScreens] = useState(false);
  const updateProject = useSelector((state) => state.updateProject);
  const updateProjecttStatus = useSelector(
    (state) => state.updateProject.status
  );
  const organisationDivision = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.divisons
  );
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const contractorsGroups = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.contractors
  );

  const projectDetailsData = useSelector((state) => state?.getProjectDetails);

  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    lngId: LNG_ID,
    flags: [6, 17],
    productId: PRODUCT_ID(),
  };

  const getDivisionData = (data) => {
    dispatch(adminAction.getDropdownDataKeyValue(data));
  };

  const allUsers = useSelector((state) => state.allUsers);
  const allGroups = useSelector((state) => state.searchAllGroup);

  function handleAllUserSearch(
    event,
    type,
    divisionId,
    assetId,
    assetType,
    priviledgeId
  ) {
    dispatch(
      userAction.searchAllUsers(
        event.target.value,
        type,
        divisionId,
        assetId,
        assetType,
        priviledgeId
      )
    );
  }
  function handleAllGroupSearch(event, divisionId) {
    dispatch(userAction.searchAllGroup(event.target.value, divisionId));
  }

  useEffect(() => {
    getDivisionData(getDropdownDataKeyValueData);
  }, []);

  const addAndUpdateProject = async (data) => {
    setSuccessScreens(true);
    if (editMode) dispatch(projectAction.postProjectUpdate(data));
    else dispatch(projectAction.postcreateProject(data));
  };
  const isLoading =
    createProject?.isLoading ||
    organisationDivision?.isLoading ||
    updateProject.isLoading ||
    projectDetailsData.isLoading;

  const isAutoSearchDataLoading = allUsers.isLoading || allGroups.isLoading;
  const divisionList = userInfo.isClient
    ? organisationDivision
    : LOGIN_ORG_ID() === ORG_ID()
    ? userInfo.divisions?.filter((i) => i?.serviceType == PRODUCT_ID())
    : organisationDivision;

  const successScree = (id, message, message1) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate={
          editMode
            ? "Project Updated Successfully"
            : "Project Created Successfully"
        }
        route={ADMIN_PROJECT}
        label1="Project ID"
        label4="Projects in in "
        label2={message1}
      ></SuccessScreen>
    );
  };
  const id = location?.state?.id;
  const editMode = location?.state?.editMode;
  const getProjectDetails = () => {
    const data = {
      projectId: id,
    };
    dispatch(projectAction.getProjectDetails(data));
  };
  useEffect(() => {
    dispatch(projectAction.clearProject());
    if (id) {
      getProjectDetails();
    }
  }, [id, editMode]);
  return (
    <>
      {statuss === API_STATUS.SUCCESS &&
      createProject.data &&
      successScreens ? (
        successScree(projectId, "")
      ) : updateProjecttStatus === "SUCCESS" &&
        updateProject &&
        successScreens ? (
        successScree(
          updateProject?.data.id,
          "Project Updated Successfully",
          "has been Updated"
        )
      ) : isLoading ? (
        <Loader />
      ) : (
        <>
          <AddProject
            onSubmit={addAndUpdateProject}
            division={divisionList}
            userGroups={allGroups?.data?.groupList}
            contractorsGroups={contractorsGroups}
            allUsers={allUsers && allUsers?.data}
            handleAllUserSearch={handleAllUserSearch}
            isLoading={isAutoSearchDataLoading}
            projectDetailsData={editMode ? projectDetailsData?.data : ""}
            editMode={editMode}
            projectId={id}
            handleAllGroupSearch={handleAllGroupSearch}
          />
        </>
      )}
    </>
  );
}
