export const projectType = {
  PROJECT_PAGE: "Project",

  POST_CREATE_PROJECT: "[POST_PROJECT] Try to create project Data",
  POST_CREATE_PROJECT_SUCCESS:
    "[POST_PROJECT_SUCCESS] Create project Data Successfull",
  POST_CREATE_PROJECT_FAILURE: "[POST_PROJECT_FAILURE] Create project  Data Failed",

  POST_PROJECT_UPDATE: "[POST_PROJECT_UPDATE] Try to update project Data",
  POST_PROJECT_UPDATE_SUCCESS:
    "[POST_PROJECT_UPDATE_SUCCESS] Update project Data Successfull",
  POST_PROJECT_UPDATE_FAILURE:
    "[POST_PROJECT_UPDATE_FAILURE] Update project  Data Failed",

  DELETE_PROJECT: "[DELETE_PROJECT] Try to delete project",
  DELETE_PROJECT_SUCCESS:
    "[DELETE_PROJECT_SUCCESS] try to delete project success",
  DELETE_PROJECT_FAILURE:
    "[DELETE_PROJECT_FAILURE] try to delete project Failure",

// GET_PROJECT_DETAILS: "[GET_PROJECT_DETAILS] Try to get project details",
// GET_PROJECT_DETAILS_SUCCESS: "[GET_PROJECT_DETAILS_SUCCESS] Try to get project details successfully",
// GET_PROJECT_DETAILS_FAILURE: "[GET_PROJECT_DETAILS_FAILURE] Try to ge project details failed",

  GET_PROJECT_LIST: "[GET_PROJECT_LIST] Get project List Data",
  GET_PROJECT_LIST_SUCCESS:
    "[GET_PROJECT_LIST_SUCCESS] Get project List Data Successfull",
  GET_PROJECT_LIST_FAILURE:
    "[GET_PROJECT_LIST_FAILURE] Get project List Data Failed",

  
    CLEAR_PROJECT: "[CLEAR_PROJECT][REQUEST] Clear PROJECT",
};

export const getProjectDetailsType={
  
  PROJECT_PAGE: "Project Details",
  GET_PROJECT_DETAILS: "[GET_PROJECT_DETAILST] Get projects DETAILS Data",
  GET_PROJECT_DETAILS_SUCCESS:
    "[GET_PROJECT_DETAILS_SUCCESS] Get project DETAILS Data Successfull",
  GET_PROJECT_DETAILS_FAILURE: "[GET_PROJECT_DETAILS_FAILURE] Get project DETAILS Data Failed",
}