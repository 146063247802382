import React from "react";
import "../../../incidents/cases/addCaseList.scss";
import Buttons from "../../../../library/custom/button/Button";
import ProjectTable from "./projectTable";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const ProjectList = ({
  addProject,
  projectsListData,
  getProjectListData,
  isLoading,
  deleteProject,
  isProjectsLoading,
  divisionDropdown,
}) => {
  return (
    <section className="grid_main_sec">
      <div className="grid_main_header two_grid">
        <div className="grid_heading_style">Projects</div>
        <div>
          {checkPrivileges([70, 71, 12]) && (
            <Buttons
              id="btnAddProject"
              varientAddIconBlue={true}
              label={"Add Project"}
              onClick={() => addProject()}
            />
          )}
        </div>
      </div>
      <ProjectTable
        projectsListData={projectsListData}
        getProjectListData={getProjectListData}
        isLoading={isLoading}
        filterShow={true}
        deleteProject={deleteProject}
        isProjectsLoading={isProjectsLoading}
        divisionDropdown={divisionDropdown}
      />
    </section>
  );
};

export default ProjectList;
