import React, { useState, useRef } from "react";
import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import Buttons from "../../../../library/custom/button/Button";
import verified from "../../../../assets/admin/userManagement/verified.svg";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ISwitch } from "../../../../constant/constant";
import FormGroup from "@mui/material/FormGroup";
import { useEffect } from "react";
import DialogBox from "../../../../library/common/ConfirmBox";
import Modal from "@mui/material/Modal";
import commonImages from "../../../../assets";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
function UserConfigurationView({
  dataBack,
  getUserConfigurationDetsilsData,
  updateUserConfiguration,
  onboardUserId,
  updateUserOnboardStatus,
  openGrandAccess,
  setOpenGrandAccess,
  openGrandResetAccess,
  setOpenGrandResetAccess,
}) {
  const data = getUserConfigurationDetsilsData;
  const [checked, setChecked] = useState(false);
  const [isStatusId, setIsStatusId] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [pwdpopMsg, setPwdpopMsg] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealOriginbalPwd, setIsRevealOriginbalPwd] = useState(false);
  const isFormValid = useRef(false);
  const [errorList, setErrorList] = useState({});
  const [checks, setChecks] = useState({
    capsLettercheck: false,
    numberCheck: false,
    pwdlength: false,
    smalllettercheck: false,
    specchar: false,
  });
  const style = {
    border: "2px rgb(238, 238, 238)",
    padding: "7px",
    marginTop: "-101px",
    marginRight: "-302px",
    position: "absolute",
    marginLeft: "234px",
    backgroundColor: "#fff",
    zIndex: 9
  };
  const handleChange = (event) => {
    setOpenModal(true);
    setSelectedRow(onboardUserId);
  };

  const deletehandle = () => {
    setOpenModal(false);
    const userEnableDisableData = {
      userid: onboardUserId,
      isEnable: !checked,
    };
    setChecked(!checked);

    updateUserOnboardStatus(userEnableDisableData);
  };

  useEffect(() => {
    if (data?.status === 4) {
      setChecked(true);
    }
  }, [data]);
  const handleClickGrantAccess = () => {
    setOpenGrandAccess(true);
  };
  const handleClickGrantAccessSystemGenerated = () => {
    setOpenGrandAccess(true);

    const result = { userId: onboardUserId, tmpPassword: "" };
    const accessData = {
      userIds: [result],
    };

    updateUserConfiguration(accessData);
  };
  const handleClickGrantResetAccess = () => {
    setOpenGrandAccess(false);
    setOpenGrandResetAccess(true);
  };

  const handleModelClose = () => {
    setOpenGrandAccess(false);
  };
  const handleModelResetClose = () => {
    setOpenGrandResetAccess(false);
    setPassword("");
    setConfirmPassword("");
    setPwdpopMsg(false);
    setIsRevealPwd(false);
    setIsRevealOriginbalPwd(false);
  };
  useEffect(() => {
    if (password.trim() === confirmPassword.trim()) {
      setErrorList((prevErrorList) => ({
        ...prevErrorList,
        confirmPassword: true,
      }));
    }
  }, [password, confirmPassword]);

  useEffect(() => {
    if (
      checks.capsLettercheck === true &&
      checks.numberCheck === true &&
      checks.pwdlength === true &&
      checks.smalllettercheck === true &&
      checks.specchar === true
    ) {
      setPwdpopMsg(false);
    } else {
      setPwdpopMsg(true);
    }
  }, [
    checks.capsLettercheck,
    checks.numberCheck,
    checks.pwdlength,
    checks.smalllettercheck,
    checks.specchar,
  ]);

  const handleOnKeyUp = (e) => {
    const value = e.target.value.trim();
    const capsLettercheck = /[A-Z]/.test(value);
    const smalllettercheck = /[a-z]/.test(value);
    // const numberCheck = /[0-9]/.test(value);
    const numberCheck = /\d/.test(value);
    const pwdlength = value.length >= 8;
    const specchar = /[!@#$`~%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value);
    const whitespace = /\s/g.test(value);
    setChecks({
      capsLettercheck,
      numberCheck,
      pwdlength,
      smalllettercheck,
      specchar,
    });
    if (whitespace === true) {
      setPassword("");
    }
    if (
      checks.capsLettercheck === true &&
      checks.numberCheck === true &&
      checks.pwdlength === true &&
      checks.smalllettercheck === true &&
      checks.specchar === true
    ) {
      setPwdpopMsg(false);

      errorList.password = true;
    } else {
      setPwdpopMsg(true);
    }
  };
  var capsClass = checks.capsLettercheck ? "valid" : "invalid";
  var numcheckClass = checks.numberCheck ? "valid" : "invalid";
  var pwdlenClass = checks.pwdlength ? "valid" : "invalid";
  var sletterClass = checks.smalllettercheck ? "valid" : "invalid";
  var specClass = checks.specchar ? "valid" : "invalid";

  /* Create Custome password submit button*/

  const validateForm = () => {
    var capsClass = checks.capsLettercheck ? "valid" : "invalid";
    var numcheckClass = checks.numberCheck ? "valid" : "invalid";
    var pwdlenClass = checks.pwdlength ? "valid" : "invalid";
    var sletterClass = checks.smalllettercheck ? "valid" : "invalid";
    var specClass = checks.specchar ? "valid" : "invalid";
    var passwodval = false;
    if (
      capsClass === "valid" &&
      numcheckClass === "valid" &&
      pwdlenClass === "valid" &&
      sletterClass === "valid" &&
      specClass === "valid" &&
      password.trim().length !== 0
    ) {
      passwodval = true;
    }
    let newErrorList = {
      ...errorList,
      password: passwodval,
      confirmPassword: confirmPassword.trim === password.trim,
    };

    setErrorList({ ...newErrorList });

    /* This validation is not based on state */
    let temporaryValidation = true;
    for (const key in newErrorList) {
      temporaryValidation = temporaryValidation && newErrorList[key];
    }
    /*
Saving it in this ref as well for
when we don't want to revalidate the
whole thing
*/
    isFormValid.current = temporaryValidation;
    return temporaryValidation;
  };

  const submitButton = async (event) => {
    event.preventDefault();
    setErrorList((prevErrorList) => ({
      ...prevErrorList,
      confirmPassword: true,
    }));
    if (validateForm()) {
      if (
        password?.trim() === confirmPassword?.trim() &&
        password &&
        confirmPassword
      ) {
        const result = { userId: onboardUserId, tmpPassword: password };
        const accessData = {
          userIds: [result],
        };

        updateUserConfiguration(accessData);
      } else {
        setErrorList((prevErrorList) => ({
          ...prevErrorList,
          confirmPassword: false,
        }));
      }
    }
    // }
  };
  return (
    <>
      <div className="viewOverideClient">
        <BackBtn dataBack={dataBack} />
      </div>

      <div className="viewOverideContact">
        <div className="commonOveride">
          {openModal && (
            <DialogBox
              open={openModal}
              rows={[]}
              header={checked ? "Confirm Disable" : "Confirm Enable"}
              deletehandle={deletehandle}
              handleCloseModal={() => setOpenModal(false)}
              content={
                checked
                  ? "Are you sure you want to Disable this User?"
                  : "Are you sure you want to Enable this User?"
              }
              view
            />
          )}
          <Modal
            open={openGrandAccess}
            onClose={handleModelClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Grid container className="modalUsrContainer">
            <Grid md={6} xs={12}><Box className="modalCustomUserBox">
                <div
                  onClick={handleModelClose}
                  className="ClosegrandAccesstyle"
                >
                  X
                </div>
                <div className="modalTitle">Grant Access</div>
                <div className="modeldesc">
                  How do you want to assign the password?
                </div>
                <Grid container className="grandaccess">
                  <Grid item lg={5} xs={12} sm={3} md={3}>
                    <Buttons
                      label="System generated"
                      onClick={handleClickGrantAccessSystemGenerated}
                      varientContainedGrey={true}
                    />
                    <Grid item lg={5} xs={12} sm={3} md={3}></Grid>
                  </Grid>

                  <Grid item lg={5.5} xs={12} sm={3} md={3}>
                    <Buttons
                      varientContained={true}
                      label={"Create Custom Password"}
                      onClick={handleClickGrantResetAccess}
                    />
                  </Grid>
                </Grid>
              </Box></Grid>
              
            </Grid>
          </Modal>
          <Modal
            open={openGrandResetAccess}
            onClose={handleModelResetClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Grid container className="modalUsrContainer">
            <Grid md={4} xs={12}>  <Box className="modalCustomUserBox">
                <div
                  onClick={handleModelResetClose}
                  className="ClosegrandAccesstyle"
                >
                  X
                </div>
                <div className="modalTitle">Grant Access</div>
                <div className="modeldescreset">
                  Create your custom password
                </div>
                <Grid container className="modalFooterBoxReset">
                  <Grid md={12} item className="resetpwd">
                    <form>
                      <div
                        className={[
                          "form-wrapper",
                          errorList.password ? null : "has-error",
                        ].join(" ")}
                      >
                        <label>
                          Password (min 8 characters){" "}
                          <span className="error"> *</span>
                        </label>
                        <div className="password-container">
                          <input
                            className={[
                              errorList.confirmPassword
                                ? "input-control"
                                : "input-control border-red",
                            ]}
                            type={isRevealOriginbalPwd ? "text" : "password"}
                            placeholder="*******"
                            value={password}
                            maxLength="20"
                            onChange={(e) => setPassword(e.target.value.trim())}
                            onKeyUp={handleOnKeyUp}
                          />
                          <img
                            className="showHidePassword"
                            title={
                              isRevealOriginbalPwd
                                ? "Hide password"
                                : "show password"
                            }
                            alt=""
                            src={
                              isRevealOriginbalPwd
                                ? commonImages.visibilityoff
                                : commonImages.visibility
                            }
                            onClick={() =>
                              setIsRevealOriginbalPwd((prevState) => !prevState)
                            }
                          />
                          {password && (
                            <div
                              style={{
                                display: pwdpopMsg === false ? "none" : "block",
                              }}
                            >
                              <div style={style}>
                                <form>
                                  <p style={{ fontWeight: "bold" }}>
                                    All checkmarks must turn green.
                                  </p>
                                  <p className={pwdlenClass}>
                                    <CheckCircleIcon className="checkbox" />
                                    At least 8 characters
                                  </p>
                                  <p className={capsClass}>
                                    <CheckCircleIcon className="checkbox" />
                                    At least 1 uppercase letter
                                  </p>
                                  <p className={sletterClass}>
                                    <CheckCircleIcon className="checkbox" />
                                    At least 1 lowercase letter
                                  </p>
                                  <p className={specClass}>
                                    <CheckCircleIcon className="checkbox" />
                                    At least 1 special character
                                  </p>
                                  <p className={numcheckClass}>
                                    <CheckCircleIcon className="checkbox" />
                                    At least 1 number{" "}
                                  </p>
                                </form>
                              </div>
                            </div>
                          )}

                          {errorList.password === false && (
                            <div className="show-error">
                              Please enter your password
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className={[
                          "form-wrapper",
                          errorList.confirmPassword ? null : "has-error",
                        ].join(" ")}
                      >
                        <label>
                          Confirm Password <span className="error"> *</span>
                        </label>
                        <div className="password-container">
                          <input
                            className={[
                              errorList.confirmPassword
                                ? "input-control"
                                : "input-control border-red",
                            ]}
                            type={isRevealPwd ? "text" : "password"}
                            placeholder="Enter Your Password"
                            value={confirmPassword}
                            maxLength="20"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                          <img
                            className="showHidePassword"
                            title={
                              isRevealPwd ? "Hide password" : "show password"
                            }
                            alt=""
                            src={
                              isRevealPwd
                                ? commonImages.visibilityoff
                                : commonImages.visibility
                            }
                            onClick={() =>
                              setIsRevealPwd((prevState) => !prevState)
                            }
                          />

                          {errorList.confirmPassword === false && (
                            <div className="show-error">
                              Password do not match
                            </div>
                          )}
                        </div>
                      </div>
                    </form>
                  </Grid>
                
              <Grid container className="restActions" mt={2}>
                  <Grid item lg={3} xs={12} sm={3} md={3}>
                    <Buttons
                      varientText={true}
                      label="Cancel"
                      onClick={handleModelResetClose}
                    />
                  </Grid>
                  <Grid item lg={3} xs={12} sm={3} md={3}>
                    <Buttons
                      varientContained={true}
                      label={"Save"}
                      onClick={submitButton}
                    />
                  </Grid>
                </Grid>
                </Grid>
              </Box>
              </Grid>
            </Grid>
          </Modal>
          <Grid container justifyContent="flex-start" alignItems="baseline">
            <Typography className="titleDetail">{data?.firstName}</Typography>
            <Grid item lg={7}>
              <Box display="flex" justifyContent="flex-end">
                <Buttons
                  disabled={data?.status === 4 || data?.status === 6}
                  id="btnAddUsers"
                  varientContained={true}
                  label={"Grant Access"}
                  onClick={(e) => handleClickGrantAccess()}
                />
              </Box>
            </Grid>
          </Grid>
          <Box className="commoninfoWrap">
            <Box className="commoninfoBox">
              <Box className="commoninfoleft">
                <Box className="columns">
                  <Typography className="listView">
                    Email ID:
                    <span className="font-w400">{data?.email}</span>
                  </Typography>
                  {data?.isEmailVerified && (
                    <Grid mr={5}>
                      <img src={verified} alt="verifiedlog" />
                    </Grid>
                  )}
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Phone Number:
                    <span className="font-w400">{data?.phoneNumber}</span>
                  </Typography>
                  {data?.isPhoneNumberVerified && (
                    <Grid mr={5}>
                      <img src={verified} alt="verifiedlog" />
                    </Grid>
                  )}
                </Box>

                <Box className="columns">
                  <Typography className="listView">
                    Created On:
                    <span className="font-w400">{data?.createdDatetime}</span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Updated On:
                    <span className="font-w400">{data?.modifiedDatetime}</span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Configuration Status:
                    <span className="font-w400">{data?.statusText}</span>
                  </Typography>
                </Box>
                {(data?.status === 4 || data?.status === 6) && (
                  <Box className="columns">
                    <Typography className="listView">
                      Account Status:
                      <span className="font-w400">
                        {
                          <Grid lg={5} xs={12} sm={6} md={4}>
                            <FormControl
                              component="fieldset"
                              variant="standard"
                              className="clientswitch"
                            >
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <ISwitch
                                      checked={checked}
                                      onChange={handleChange}
                                      name="status"
                                    />
                                  }
                                  label={checked ? "Enabled" : "Disabled"}
                                />
                              </FormGroup>
                            </FormControl>
                          </Grid>
                        }
                      </span>
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
}

export default UserConfigurationView;
