import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import NORow from "../../../../library/custom/noRow/noRow";
import { TableLoader } from "../../../../library/common/Loader";
import { getDateWithDayPast, getFormatedDate } from "../../../../utils/helper";
import {
  ORG_ID,
  PAGE_SIZE,
  ROW_PER_PAGE_SIZE,
  HBNEXT_ORG_ID,
} from "../../../../constant/constant";
import Cards from "../../../../feature/reports/component/Cards";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import DatePicker from "../../../../library/custom/datePicker/DatePicker";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as assetsAction from "../../../../redux/actions/assetsAction";

const SwRainfallData = ({
  isSearchClientLoading,
  searchClient,
  allClients,
  swGetRainFallDataList,
  swRainFallData,
  isLoading,
}) => {
  const autoComp = useRef(null);
  const dispatch = useDispatch();
  const [days, setDays] = useState("");
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [clients, setClients] = useState("");
  const [projects, setProjects] = useState();
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const filterDays = [
    {
      key: 0,
      value: "Today",
    },
    {
      key: 7,
      value: "7 days",
    },
    {
      key: 14,
      value: "14 days",
    },
    {
      key: 30,
      value: "30 days",
    },
    {
      key: 12,
      value: "Custom",
    },
    {
      key: 13,
      value: "Reset",
    },
  ];

  const onChangeClients = (value) => {
    setClients(value);
    setProjects("");
    const data = {
      page: 1,
      pageSize: pageSize,
      rainfallType: 1,
      projectId: projects?.key ? projects?.key : 0,
      fromDate: startDate ? getFormatedDate(startDate) : "",
      toDate: endDate ? getFormatedDate(endDate) : "",
      search: "",
      OrgId: value?.key ? value?.key : parseInt(ORG_ID()),
    };
    swGetRainFallDataList(data);
  };

  const onChangeProjects = (value) => {
    setProjects(value);
    const data = {
      page: 1,
      pageSize: pageSize,
      rainfallType: 1,
      projectId: value?.key ? value?.key : 0,
      fromDate: startDate ? getFormatedDate(startDate) : "",
      toDate: endDate ? getFormatedDate(endDate) : "",
      search: "",
      OrgId: clients?.key ? clients?.key : parseInt(ORG_ID()),
    };
    swGetRainFallDataList(data);
  };

  const allProject = useSelector((state) => state.getAssetDynomoList);
  const isProjectLoading = allProject?.isLoading;
  function handleAllProjectSearch(event, value) {
    dispatch(
      assetsAction.getAssetDynomoList({
        orgId: clients?.key ? clients?.key : parseInt(ORG_ID()),
        searchText: event.target.value,
        type: 5,
      })
    );
  }

  const typeOnchange = (event, value) => {
    searchClient(event?.target?.value);
  };

  const cardOnClick = (value) => {
    setDays(value);
    if (days.key === value.key) {
      setDays("");
      const data = {
        page: 1,
        pageSize: pageSize,
        rainfallType: 1,
        projectId: projects?.key ? projects?.key : 0,
        fromDate: "",
        toDate: "",
        search: "",
        OrgId: clients?.key ? clients?.key : parseInt(ORG_ID()),
      };
      swGetRainFallDataList(data);
    } else {
      if (value.key !== 12) {
        let dateObj = getDateWithDayPast(value.key);
        const data = {
          page: 1,
          pageSize: pageSize,
          rainfallType: 1,
          projectId: projects?.key ? projects?.key : 0,
          fromDate: dateObj.pastData ? dateObj.pastData : "",
          toDate: dateObj.getCurrentDate ? dateObj.getCurrentDate : "",
          search: "",
          OrgId: clients?.key ? clients?.key : parseInt(ORG_ID()),
        };
        swGetRainFallDataList(data);
        setEndDate(dateObj.getCurrentDate);
        setStartDate(dateObj.pastData);
      }
      if (value.key === 13) {
        setStartDate("");
        setEndDate("");
        const data = {
          page: 1,
          pageSize: pageSize,
          rainfallType: 1,
          projectId: projects?.key ? projects?.key : 0,
          fromDate: "",
          toDate: "",
          search: "",
          OrgId: clients?.key ? clients?.key : parseInt(ORG_ID()),
        };
        swGetRainFallDataList(data);
      }
    }
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      page: newPage + 1,
      pageSize: pageSize,
      rainfallType: 1,
      projectId: projects?.key ? projects?.key : 0,
      fromDate: startDate ? getFormatedDate(startDate) : "",
      toDate: endDate ? getFormatedDate(endDate) : "",
      search: "",
      OrgId: clients?.key ? clients?.key : parseInt(ORG_ID()),
    };
    swGetRainFallDataList(data);
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    const data = {
      page: 1,
      pageSize: newPazeSize,
      rainfallType: 1,
      projectId: projects?.key ? projects?.key : 0,
      fromDate: startDate ? getFormatedDate(startDate) : "",
      toDate: endDate ? getFormatedDate(endDate) : "",
      search: "",
      OrgId: clients?.key ? clients?.key : parseInt(ORG_ID()),
    };
    swGetRainFallDataList(data);
  };

  const onchangeStartdatePicker = (e) => {
    setStartDate(e);
  };

  const onchangeEnddatePicker = (e) => {
    setEndDate(e);
  };

  const formatPrecipData = (data) => {
    return data.map((item) => ({
      ...item,
      evenTrackPrecipAmt: parseFloat(item.evenTrackPrecipAmt.toFixed(2)),
      precipAmt: parseFloat(item.precipAmt.toFixed(2)),
    }));
  };

  const formattedData = swRainFallData?.data?.historyPrecipList
    ? formatPrecipData(swRainFallData.data.historyPrecipList)
    : [];

  useEffect(() => {
    if (startDate && endDate && days.key === 12) {
      const data = {
        page: 1,
        pageSize: pageSize,
        rainfallType: 1,
        projectId: projects?.key ? projects?.key : 0,
        fromDate: startDate ? getFormatedDate(startDate) : "",
        toDate: endDate ? getFormatedDate(endDate) : "",
        search: "",
        OrgId: clients?.key ? clients?.key : parseInt(ORG_ID()),
      };
      swGetRainFallDataList(data);
    }
  }, [startDate, endDate]);

  const credcloumns = [
    { field: "projectName", headerName: "Project", flex: 4.5 },
    { field: "organisationName", headerName: "Client", flex: 4 },
    { field: "precipDateFormat", headerName: "Date", flex: 4 },
    { field: "precipAmt", headerName: "WWP", flex: 3 },
    {
      field: "evenTrackPrecipAmt",
      headerName: "ENVRTRACK",
      flex: 3,
    },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={swRainFallData?.data?.historyPrecipCount ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={formattedData}
          fileNameXL={"InspectionList"}
        />
      ),
      width: 80,
      sortable: false,
      disableClickEventBubbling: true,
    },
  ];
  return (
    <>
      <div className="overidePersonal">
        <Grid container>
          <Grid lg={12} md={8} xs={12}>
            <Grid container>
              <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
                <div className="heading_style">{" Rainfall Data"}</div>
              </Grid>
            </Grid>
            <Grid item container spacing={3} mt={3}>
              {parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) && (
                <Grid item lg={5} xs={12} sm={12} md={6}>
                  <FormControl fullWidth>
                    <FormLabels label="Select Client" isRequired={false} />
                    <SearchAutoComplete
                      name="orgName"
                      placeholderText="Search Client"
                      noOptionsTextMsg="No Client"
                      optionsData={allClients?.data ?? []}
                      typeOnchange={(event, value) =>
                        typeOnchange(event, value)
                      }
                      isKeyValuePair={true}
                      value={clients}
                      onChange={(event, value) => onChangeClients(value)}
                      isLoading={isSearchClientLoading}
                      autoCompClear={autoComp}
                    />
                  </FormControl>
                </Grid>
              )}
            </Grid>

            <Grid item container spacing={3} mt={3}>
              <Grid item lg={5} xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <FormLabels label="Select Project" isRequired={false} />
                  <SearchAutoComplete
                    name="Add Individual Users"
                    placeholderText="Search Project"
                    optionsData={allProject?.data ?? []}
                    typeOnchange={(event, value) =>
                      handleAllProjectSearch(event, value)
                    }
                    isKeyValuePair={true}
                    value={projects}
                    onChange={(e, value) => onChangeProjects(value)}
                    isLoading={isProjectLoading}
                    autoCompClear={autoComp}
                    noOptionsTextMsg={"No Project"}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item lg={12} xs={12} sm={12} md={8} mt={5}>
              <FormLabels label={"Select Time Period"} />
              <Cards
                staticCardData={true}
                cardData={filterDays}
                selectedVehicleAccident={days}
                cardClickHandler={cardOnClick}
              />
            </Grid>
            {days?.value === "Custom" ? (
              <>
                <Grid item container mt={1} spacing={1}>
                  <Grid item lg={4} xs={12} sm={12}>
                    <FormLabels
                      label={"Custom Time Period"}
                      isRequired={false}
                    />
                  </Grid>
                </Grid>
                <Grid item container spacing={2} mb={2}>
                  <Grid item lg={5} xs={12} sm={12} md={4}>
                    <FormLabels label={"From"} isRequired={false} />
                    <DatePicker
                      maxDateEnable={false}
                      value={startDate}
                      disabled={false}
                      onChangeHandler={(e) => onchangeStartdatePicker(e)}
                    />
                  </Grid>
                  <Grid item lg={5} xs={12} sm={12} md={4}>
                    <FormLabels label={"To"} isRequired={false} />
                    <DatePicker
                      maxDateEnable={false}
                      minDate={startDate}
                      value={endDate}
                      disabled={false}
                      onChangeHandler={(e) => onchangeEnddatePicker(e)}
                    />
                  </Grid>
                </Grid>
              </>
            ) : null}

            <section className="grid_main_sec">
              <div className="grid_main_body">
                <div className="desc_box row_uniq">
                  <div className="table_style_ui">
                    <GridTable
                      // getRowId={(r) => r.precipId}
                      // rows={swRainFallData?.data?.historyPrecipList ?? []}
                      // rowCount={swRainFallData?.data?.historyPrecipCount ?? 0}
                      // columns={credcloumns}
                      // loading={isLoading}
                      // checkboxSelection={false}
                      // page={page}
                      // pageSize={PAGE_SIZE}
                      // // onPageSizeChange={handlePageSizeChange}
                      // onPageChange={handlePagignation}
                      // backIconButtonProps={{ disabled: false }}
                      // disableSelectionOnClick
                      // components={{
                      //   LoadingOverlay: TableLoader,
                      //   NoRowsOverlay: NORow,
                      // }}
                      getRowId={(r) => r.precipId}
                      rows={formattedData}
                      rowCount={swRainFallData?.data?.historyPrecipCount ?? 0}
                      columns={credcloumns}
                      loading={isLoading}
                      checkboxSelection={false}
                      page={page}
                      pagination
                      pageSize={pageSize}
                      rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                      onPageSizeChange={handlePageSizeChange}
                      onPageChange={handlePagignation}
                      backIconButtonProps={{ disabled: false }}
                      disableSelectionOnClick
                      components={{
                        LoadingOverlay: TableLoader,
                        NoRowsOverlay: NORow,
                      }}
                    />
                  </div>
                </div>
              </div>
            </section>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default SwRainfallData;
