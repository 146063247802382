import React from "react";
import AssetManagementList from "../component/assetManagement/assetManagementList";

import {
  ADMIN_PROJECT,
  ADMIN_VEHICLES,
  ADMIN_EQUIPMENTS,
  ADMIN_ASSETS,
} from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";

const AssetManagementContainer = () => {
  const navigate = useNavigate();

  const onClickHandler = (id) => {
    if (id === 1) {
      navigate(ADMIN_PROJECT);
    } else if (id === 2) {
      navigate(ADMIN_VEHICLES);
    } else if (id === 3) {
      navigate(ADMIN_EQUIPMENTS);
    } else if (id === 4) {
      navigate(ADMIN_ASSETS);
    }
  };

  return (
    <>
      <div className="dashTitle">Asset Management</div>
      <AssetManagementList onClick={onClickHandler} />
    </>
  );
};

export default AssetManagementContainer;
