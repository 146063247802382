import React, { useEffect, useRef, useState } from "react";
import "./addAssetContainerStyle.scss";
import { FormControl, Grid, RadioGroup } from "@mui/material";
import { InputField } from "../../../../library/custom/textBox/InputField";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import DatePicker from "../../../../library/custom/datePicker/DatePicker";
import TextArea from "../../../../library/custom/textArea/TextArea";
import Buttons from "../../../../library/custom/button/Button";
import {
  ASSET_GRID_COLOUMNS,
  ASSET_TEMPLATE_URL,
  DIVISION_ID,
  LNG_ID,
  ORG_ID,
  USER_ID,
  USER_TYPE,
} from "../../../../constant/constant";
import { getFormatedDate, dateValiadtor } from "../../../../utils/helper";
import UsersChip from "../../../../library/custom/usersChip/UsersChip";
import { ADMIN_ASSETS } from "../../../../constant/routeContant";
import RadioButton from "../../../../library/custom/radioButton/RadioButton";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import BulkUpload from "../../../../library/custom/bulkUpload/BulkUpload";
import { checkPrivileges } from "../../../../utils/rolesHelper";
const AddAsset = ({
  onSubmit,
  division,
  assetCategory,
  userGroups,
  allUsers,
  handleAllUserSearch,
  isLoading,
  assetDetailsData,
  editMode,
  assetId,
  handleAllGroupSearch,
}) => {
  useEffect(() => {
    if (assetDetailsData) {
      setIndividualUsersIds(
        ...individualUsersIds,
        assetDetailsData?.assignToUser?.map((e) => e.key)
      );
      if (assetDetailsData?.assignToUser.length > 0) {
        setIndividualUsersList(
          ...individualUsersList,
          assetDetailsData?.assignToUser?.map((e) => e)
        );
      }
      setuserGroupIds(
        ...userGroupIds,
        assetDetailsData?.assignToGroup?.map((e) => e.key)
      );
      if (assetDetailsData?.assignToGroup.length > 0) {
        setUsersGroupList(
          ...userGroupList,
          assetDetailsData?.assignToGroup?.map((e) => e)
        );
      }
      setInspectorIds(
        ...inspectorIds,
        assetDetailsData?.assignToInspector?.map((e) => e.key)
      );
      if (assetDetailsData?.assignToInspector.length > 0) {
        setInspectorList(
          ...inspectorList,
          assetDetailsData?.assignToInspector?.map((e) => e)
        );
      }
      setsupervisorId(
        ...supervisorId,
        assetDetailsData?.assignToSupervisor?.map((e) => e.key)
      );
      if (assetDetailsData?.assignToSupervisor.length > 0) {
        setsupervisoList(
          ...supervisoList,
          assetDetailsData?.assignToSupervisor?.map((e) => e)
        );
      }

      setContractorGroupsIds(
        ...contractorGroupsIds,
        assetDetailsData?.assignToSubcontractor?.map((e) => e.key)
      );
      if (assetDetailsData?.assignToSubcontractor?.length > 0) {
        setContractorGroupsList(
          ...contractorGroupsList,
          assetDetailsData?.assignToSubcontractor?.map((e) => e)
        );
      }

      setFormValue({
        ...formValue,
        isActive: assetDetailsData.statusId === 1 ? "1" : "2",
        assetTypeId: assetDetailsData.assetTypeId,
        assetCategory: assetDetailsData.assetTypeId,
        assetName: assetDetailsData.assetName,
        divisionIds: assetDetailsData.divisionId,
        division: assetDetailsData.divisionId,
        startDate:
          assetDetailsData.startDate === null
            ? null
            : getFormatedDate(assetDetailsData.startDate),
        completionDate:
          assetDetailsData.completionDate === null
            ? null
            : getFormatedDate(assetDetailsData.completionDate),
        assetType: assetDetailsData.assetType,
        supervisorId: assetDetailsData.supervisorId,
        supervisor: assetDetailsData.supervisorId,
        notes: assetDetailsData.notes,
        subContractor: "",
      });
    }
  }, [assetDetailsData]);

  const [formValue, setFormValue] = useState({
    startDate: new Date(),
    assetName: "",
    assetTypeId: "",
    completionDate: null,
    notes: "",
    isActive: "",
    supervisor: "",
    supervisorId: "",
    assignedTo: "",
    assetType: "",
    assetCategory: "",
    division:
      DIVISION_ID() == "null"
        ? ""
        : DIVISION_ID() == 0
        ? "undefined"
        : DIVISION_ID(),
    multiAutoSearchVal: "",
    createdBy: parseInt(USER_ID()),
    organisationId: ORG_ID(),
    assignedIds: null,
    subContractor: "",
    divisionIds:
      DIVISION_ID() == "null"
        ? ""
        : DIVISION_ID() == 0
        ? "undefined"
        : DIVISION_ID(),
  });

  const dataBack = {
    title: "Back to Assets",
    route: ADMIN_ASSETS,
  };

  const [isassetTypeId, setisAssetTypeId] = useState(false);
  const [isassentname, setisassentname] = useState(false);
  const [isdivisionId, setisdivisionId] = useState(false);
  const [isstartDate, setisstartDate] = useState(false);
  const [isstartDateErrMsg, setisstartDateErrMsg] = useState(false);
  const [isCompletionDate, setisCompletionDate] = useState(false);
  const [isCompletionDateErrMsg, setisCompletionDateErrMsg] = useState(false);
  function mappedData() {
    let errors = false;
    if (formValue?.assetTypeId?.length === 0) {
      setisAssetTypeId(true);
      errors = true;
    }
    if (formValue?.assetName?.length === 0) {
      setisassentname(true);
      errors = true;
    }
    if (formValue?.divisionIds?.length === 0) {
      setisdivisionId(true);
      errors = true;
    }
    if (formValue?.startDate === null) {
      setisstartDate(true);
      errors = true;
      setisstartDateErrMsg("Start Date is required");
    }
    if (formValue.startDate != null) {
      const validDate = dateValiadtor(getFormatedDate(formValue.startDate));
      if (validDate === false) {
        setisstartDate(true);
        errors = true;
        setisstartDateErrMsg("Invalid Date");
      }
    }
    if (formValue.completionDate != null) {
      const validDate = dateValiadtor(
        getFormatedDate(formValue.completionDate)
      );
      if (validDate === false) {
        setisCompletionDate(true);
        errors = true;
        setisCompletionDateErrMsg("Invalid Date");
      }
    }
    if (errors) {
      return false;
    }

    let data;
    if (editMode) {
      data = {
        assetTypeId: formValue.assetTypeId,
        assetName: formValue.assetName,
        assignToSupervisor: supervisorId,
        divisionId: formValue.divisionIds,
        startDate: getFormatedDate(formValue.startDate),
        completionDate:
          formValue.completionDate === null
            ? null
            : getFormatedDate(formValue.completionDate),
        notes: formValue.notes,
        assignToUser: individualUsersIds,
        assignToGroup: userGroupIds,
        assignToInspector: inspectorIds,
        assetId: assetId,
        modifiedBy: 7,
        isActive: formValue.isActive == "1" ? true : false,
        lngId: LNG_ID,
        assignToSubcontractor: contractorGroupsIds,
      };
    } else {
      data = {
        assetTypeId: formValue.assetTypeId,
        assetName: formValue.assetName,
        assignToSupervisor: supervisorId,
        divisionId: formValue.divisionIds,
        startDate: getFormatedDate(formValue.startDate),
        completionDate:
          formValue.completionDate === null
            ? null
            : getFormatedDate(formValue.completionDate),
        notes: formValue.notes,
        assignToUser: individualUsersIds,
        assignToGroup: userGroupIds,
        assignToInspector: inspectorIds,
        createdBy: parseInt(USER_ID()),
        organisationId: ORG_ID(),
        lngId: LNG_ID,
        assignToSubcontractor: contractorGroupsIds,
      };
    }
    return data;
  }
  const submitData = () => {
    const data = mappedData();
    if (!data) {
    } else {
      editMode ? onSubmit(data) : onSubmit([data]);
    }
  };

  const autoCompSupervisor = useRef(null);
  const autoComp = useRef(null);
  const autoCompInsoector = useRef(null);
  const autoCompUser = useRef(null);
  const autoCompContractor = useRef(null);

  const [supervisoList, setsupervisoList] = useState([]);
  const [userGroupList, setUsersGroupList] = useState([]);
  const [individualUsersList, setIndividualUsersList] = useState([]);
  const [inspectorList, setInspectorList] = useState([]);
  const [contractorGroupsList, setContractorGroupsList] = useState([]);
  const [supervisor, setsupervisor] = useState(1);
  const [individualUsers, setIndividualUsers] = useState(1);
  const [usersGroup, setUsersGroup] = useState(1);
  const [inspector, setInspector] = useState(1);
  const [contractorGroups, setContractorGroups] = useState(1);
  const [supervisorId, setsupervisorId] = useState([]);
  const [userGroupIds, setuserGroupIds] = useState([]);
  const [individualUsersIds, setIndividualUsersIds] = useState([]);
  const [inspectorIds, setInspectorIds] = useState([]);
  const [contractorGroupsIds, setContractorGroupsIds] = useState([]);

  const deleteIndividualUsersData = (id) => {
    const updateIndividualUsersList = individualUsersList.filter(
      (list) => list.key !== id
    );
    const updateindividualUsersIds = individualUsersIds.filter(
      (list) => list !== id
    );
    setIndividualUsersList(updateIndividualUsersList);
    setIndividualUsersIds(updateindividualUsersIds);
  };

  const deleteusersGroupData = (id) => {
    const updateUserGroupList = userGroupList.filter((list) => list.key !== id);
    const updateUserGroupIds = userGroupIds.filter((list) => list !== id);
    setUsersGroupList(updateUserGroupList);
    setuserGroupIds(updateUserGroupIds);
  };

  const deleteInspectorData = (id) => {
    const updateInspectorList = inspectorList.filter((list) => list.key !== id);
    const updateInspectorIds = inspectorIds.filter((list) => list !== id);
    setInspectorList(updateInspectorList);
    setInspectorIds(updateInspectorIds);
  };

  const deleteSupervisorData = (id) => {
    const updateSupervisorList = supervisoList.filter(
      (list) => list.key !== id
    );
    const updateSupervisorIds = supervisorId.filter((list) => list !== id);
    setsupervisoList(updateSupervisorList);
    setsupervisorId(updateSupervisorIds);
  };

  const deleteContractorGroupsData = (id) => {
    const updatecontractorGroupsList = contractorGroupsList.filter(
      (list) => list.key !== id
    );
    const updateContractorGroupsIds = contractorGroupsIds.filter(
      (list) => list !== id
    );
    setContractorGroupsList(updatecontractorGroupsList);
    setContractorGroupsIds(updateContractorGroupsIds);
  };

  const addIndiVidualUserData = () => {
    if (!individualUsers.key) return;
    const index = individualUsersList.findIndex(
      (object) => object.key === individualUsers.key
    );
    if (index === -1) {
      setIndividualUsersIds([...individualUsersIds, individualUsers.key]);
      setIndividualUsersList([...individualUsersList, individualUsers]);
    }
    const ele = autoCompUser.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };

  const addUserGroupData = () => {
    if (!usersGroup.key) return;
    const index = userGroupList.findIndex(
      (object) => object.key === usersGroup.key
    );
    if (index === -1) {
      setuserGroupIds([...userGroupIds, usersGroup.key]);
      setUsersGroupList([...userGroupList, usersGroup]);
    }
    const ele = autoComp.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };

  const addInspectorData = () => {
    if (!inspector.key) return;
    const index = inspectorList.findIndex(
      (object) => object.key === inspector.key
    );
    if (index === -1) {
      setInspectorIds([...inspectorIds, inspector.key]);
      setInspectorList([...inspectorList, inspector]);
    }
    const ele = autoCompInsoector.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };

  const addSupervisorData = () => {
    if (!supervisor.key) return;
    const index = supervisoList.findIndex(
      (object) => object.key === supervisor.key
    );
    if (index === -1) {
      setsupervisorId([...supervisorId, supervisor.key]);
      setsupervisoList([...supervisoList, supervisor]);
    }
    const ele = autoCompSupervisor.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };

  const addContractorGroupsData = () => {
    if (!contractorGroups.key) return;
    const index = contractorGroupsList.findIndex(
      (object) => object.key === contractorGroups.key
    );
    if (index === -1) {
      setContractorGroupsIds([...contractorGroupsIds, contractorGroups.key]);
      setContractorGroupsList([...contractorGroupsList, contractorGroups]);
    }
    const ele = autoCompContractor.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  };

  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const handleFileChanges = (data) => {
    const newData = data.map((x) => {
      x.assetTypeId = parseInt(x.assetTypeId);
      x.assignToSupervisor =
        x.assignToSupervisor === undefined
          ? []
          : x.assignToSupervisor.split(",");
      x.divisionId = parseInt(x.divisionId);
      x.assignToUser =
        x.assignToUser === undefined ? [] : x.assignToUser.split(",");
      x.assignToGroup =
        x.assignToGroup === undefined ? [] : x.assignToGroup.split(",");
      x.assignToInspector =
        x.assignToInspector === undefined ? [] : x.assignToInspector.split(",");
      x.assignToSubcontractor =
        x.assignToSubcontractor === undefined
          ? []
          : x.assignToSubcontractor.split(",");
      x.lngId = 1;
      x.startDate = x.startDate?.replace(/\//g, "-");
      x.completionDate = x.completionDate?.replace(/\//g, "-");
      x.createdBy = parseInt(USER_ID());
      x.organisationId = parseInt(ORG_ID());
      return x;
    });
    onSubmit(newData);
  };

  const [isSupervisor, setisSupervisor] = useState(false);
  const [isInspector, setisInspector] = useState(false);
  const [isIndividualUser, setisIndividualUser] = useState(false);
  const [isUserGroup, setisUserGroup] = useState(false);
  const [isContractor, setisContractor] = useState(false);
  const [isSupervisorErrMsg, setisSupervisorErrMsg] = useState("");
  const [isInspectorErrMsg, setisInspectorErrMsg] = useState("");
  const [isIndividualUserErrMsg, setisIndividualUserErrMsg] = useState("");
  const [isUserGroupErrMsg, setisUserGroupErrMsg] = useState("");
  const [isContractorErrMsg, setisContractorErrMsg] = useState("");

  const [assetSupervisorValue, setAssetSupervisorValue] = useState("");
  const onSelectAssetSupervisor = (value) => {
    setAssetSupervisorValue({
      key: value?.key ?? "",
      value: value?.value ?? "",
    });
    setsupervisor(value);
  };

  const mapBulkError = (data) => {
    let assetsWithError = [];
    data.forEach((x) => {
      const validDate = dateValiadtor(
        getFormatedDate(x.startDate?.replace(/\//g, "-"))
      );
      if (
        x.assetTypeId?.trim() === undefined ||
        null ||
        "" ||
        x.assetName?.trim() === undefined ||
        null ||
        "" ||
        x.divisionId?.trim() === undefined ||
        null ||
        "" ||
        !validDate
      ) {
        assetsWithError.push(x);
        return;
      }
    });
    return assetsWithError;
  };
  return (
    <>
      <div className="overidePersonal">
        <Grid container item lg={9} xs={12} sm={12} md={9}>
          <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
            <div className="heading_style">
              {editMode ? "Edit Asset" : "Add Asset"}
            </div>
          </Grid>
          <Grid item lg={6} xs={12} sm={12} md={4} textAlign="right">
            {checkPrivileges([37, 12]) && !editMode ? (
              <Buttons
                aria-describedby="dd"
                variant="contained"
                type="button"
                name="btn"
                label={isBulkUpload ? "Single Asset" : "Multiple Assets"}
                varientContained={true}
                onClick={() => setIsBulkUpload(!isBulkUpload)}
              ></Buttons>
            ) : (
              ""
            )}
          </Grid>
        </Grid>

        {editMode && (
          <Grid item container md={9} mt={3} spacing={2}>
            <Grid item lg={4} mt={3} xs={12} sm={12} md={4}>
              {checkPrivileges([37, 12]) && (
                <FormControl fullWidth>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    className="radioGroup"
                  >
                    <RadioButton
                      checked={formValue.isActive == "1"}
                      value="1"
                      label="Active"
                      onChange={(e) =>
                        setFormValue({
                          ...formValue,
                          isActive: e.target.value,
                        })
                      }
                    ></RadioButton>
                    <RadioButton
                      checked={formValue.isActive == "2"}
                      value="2"
                      label="Inactive"
                      onChange={(e) =>
                        setFormValue({
                          ...formValue,
                          isActive: e.target.value,
                        })
                      }
                    ></RadioButton>
                  </RadioGroup>
                </FormControl>
              )}
            </Grid>
            <Grid item lg={8} xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <InputField
                  fullWidth={true}
                  type="text"
                  label={"Asset ID"}
                  disabled={true}
                  value={assetId}
                />
              </FormControl>
            </Grid>
          </Grid>
        )}
        {isBulkUpload ? (
          <Grid container>
            <Grid item lg={9} xs={9} sm={9} md={9} mt={4}>
              <BulkUpload
                mapBulkError={mapBulkError}
                handleFileChanges={handleFileChanges}
                paraText1={"Drop the files here ..."}
                paraText2={"Drop the files here, "}
                paraText3={"or Browse"}
                templateUrl={ASSET_TEMPLATE_URL}
                templateText="Download Template"
                gridColoumns={ASSET_GRID_COLOUMNS}
                isCustomColoumns={true}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container item md={9} xs={9} className="addShadowBox">
            <Grid item container spacing={2}>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <FormLabels label="Asset Type" isRequired={true} />
                  <SelectMenu
                    placeholder="Please Select"
                    listData={assetCategory}
                    value={formValue.assetCategory}
                    onchangehandler={(e) => {
                      setFormValue({
                        ...formValue,
                        assetCategory: e.target.value,
                        assetTypeId: e.target.value,
                      });
                    }}
                    isError={isassetTypeId}
                    errorMsg={
                      isassetTypeId
                        ? "Selection is required for this field "
                        : ""
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <InputField
                    isRequired={true}
                    fullWidth={true}
                    type="text"
                    label={"Asset Name"}
                    value={formValue.assetName}
                    onChange={(e) => {
                      if (
                        e.target.value === " " &&
                        formValue.assetName.trim() === ""
                      )
                        setFormValue({
                          ...formValue,
                          assetName: "",
                        });
                      else if (e.target.value.length > 200) {
                        return;
                      } else
                        setFormValue({
                          ...formValue,
                          assetName: e.target.value,
                        });
                    }}
                    isError={isassentname}
                    errorMsg={
                      isassentname ? "Please enter this required field" : ""
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid item container mt={3} spacing={2}>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <FormLabels label="Division" isRequired={true} />
                  <SelectMenu
                    placeholder="Please Select"
                    listData={division}
                    //value={formValue.division}
                    value={
                      formValue.division === "undefined"
                        ? null
                        : formValue.division
                    }
                    onchangehandler={(e) => {
                      setFormValue({
                        ...formValue,
                        division: e.target.value,
                        divisionIds: e.target.value,
                      });

                      setisIndividualUser(false);
                      setIndividualUsersIds([]);
                      setIndividualUsersList([]);
                      setisUserGroup(false);
                      setuserGroupIds([]);
                      setUsersGroupList([]);
                      setisInspector(false);
                      setInspectorIds([]);
                      setInspectorList([]);
                      setisSupervisor(false);
                      setsupervisorId([]);
                      setsupervisoList([]);
                      setisContractor(false);
                      setContractorGroupsList([]);
                      setContractorGroupsIds([]);
                    }}
                    disabled={DIVISION_ID() > 0}
                    isError={isdivisionId}
                    errorMsg={
                      isdivisionId ? "Selection is required for this field" : ""
                    }
                  />
                </FormControl>
              </Grid>
              <Grid container item mt={3}>
                <Grid lg={6} md={6} sm={6} xs={12}>
                  {isSupervisor && (
                    <div className="errorMsg">{isSupervisorErrMsg}</div>
                  )}
                  <FormLabels label="Asset Supervisor" />
                  <SearchAutoComplete
                    name="Asset Supervisor"
                    optionsData={isLoading ? [] : allUsers?.users ?? []}
                    typeOnchange={(event, value) => {
                      if (formValue.division === "undefined") {
                        setisSupervisor(true);
                        setisSupervisorErrMsg("Please select division first");
                        return;
                      } else {
                        // console.log(formValue.division)
                        setisSupervisor(false);
                        setisSupervisorErrMsg("");
                        handleAllUserSearch(
                          event,
                          USER_TYPE.DIVISION_EMPLOYEE,
                          formValue.division,
                          0,
                          0,
                          141
                        );
                      }
                    }}
                    isKeyValuePair={true}
                    value={assetSupervisorValue}
                    onChange={(e, value) => onSelectAssetSupervisor(value)}
                    isLoading={isLoading}
                    autoCompClear={autoCompSupervisor}
                  />
                </Grid>

                <Grid
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className="smallAddBtn"
                  mt={3.5}
                  pl={2}
                >
                  <Buttons
                    aria-describedby="dd"
                    variant="contained"
                    type="button"
                    name="btn"
                    label={"Add"}
                    onClick={() => addSupervisorData()}
                    id="btnAddEquipment"
                    varientAddIconBlue={true}
                  ></Buttons>
                </Grid>
                {/* <Grid mt={4} container gap={1}>
                <UsersChip
                  userList={inspectorList}
                  handleDelete={(e) => deleteInspectorData(e)}
                />
              </Grid> */}
                <Grid mt={4} container gap={1}>
                  <UsersChip
                    userList={supervisoList}
                    handleDelete={(e) => deleteSupervisorData(e)}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container item mt={3}>
              <Grid lg={6} md={6} sm={6} xs={12}>
                {isInspector && (
                  <div className="errorMsg">{isInspectorErrMsg}</div>
                )}
                <FormLabels label="Assign Inspector" />
                <SearchAutoComplete
                  name="Assign Inspector"
                  optionsData={isLoading ? [] : allUsers?.users ?? []}
                  typeOnchange={(event, value) => {
                    if (formValue.division === "undefined") {
                      setisInspector(true);
                      setisInspectorErrMsg("Please select division first");
                      return;
                    } else {
                      setisInspector(false);
                      setisInspectorErrMsg("");
                      handleAllUserSearch(
                        event,
                        USER_TYPE.DIVISION_EMPLOYEE,
                        formValue.division,
                        0,
                        0,
                        140
                      );
                    }
                  }}
                  isKeyValuePair={true}
                  value={formValue.supervisor}
                  onChange={(e, value) => setInspector(value)}
                  isLoading={isLoading}
                  autoCompClear={autoCompInsoector}
                />
              </Grid>
              <Grid
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className="smallAddBtn"
                mt={3.5}
                pl={2}
              >
                <Buttons
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={"Add"}
                  onClick={() => addInspectorData()}
                  id="btnAddEquipment"
                  varientAddIconBlue={true}
                ></Buttons>
              </Grid>
              <Grid mt={4} container gap={1}>
                <UsersChip
                  userList={inspectorList}
                  handleDelete={(e) => deleteInspectorData(e)}
                />
              </Grid>
            </Grid>

            <Grid container item mt={3}>
              <Grid lg={6} md={6} sm={6} xs={12}>
                {isIndividualUser && (
                  <div className="errorMsg">{isIndividualUserErrMsg}</div>
                )}
                <FormLabels label="Add Individual Users" />
                <SearchAutoComplete
                  name="Add Individual Users"
                  optionsData={isLoading ? [] : allUsers?.users ?? []}
                  typeOnchange={(event, value) => {
                    if (formValue.division === "undefined") {
                      setisIndividualUser(true);
                      setisIndividualUserErrMsg("Please select division first");
                      return;
                    } else {
                      setisIndividualUser(false);
                      setisIndividualUserErrMsg("");
                      handleAllUserSearch(
                        event,
                        USER_TYPE.NONPRIVILEGED,
                        formValue.division
                      );
                    }
                  }}
                  isKeyValuePair={true}
                  value={formValue.supervisor}
                  onChange={(e, value) => setIndividualUsers(value)}
                  isLoading={isLoading}
                  autoCompClear={autoCompUser}
                />
              </Grid>

              <Grid
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className="smallAddBtn"
                mt={3.5}
                pl={2}
              >
                <Buttons
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={"Add"}
                  onClick={() => addIndiVidualUserData()}
                  id="btnAddEquipment"
                  varientAddIconBlue={true}
                ></Buttons>
              </Grid>
              <Grid mt={4} container gap={1}>
                <UsersChip
                  userList={individualUsersList}
                  handleDelete={(e) => deleteIndividualUsersData(e)}
                />
              </Grid>
            </Grid>

            <Grid container item mt={3}>
              <Grid lg={6} md={6} sm={6} xs={12}>
                {isUserGroup && (
                  <div className="errorMsg">{isUserGroupErrMsg}</div>
                )}
                <FormLabels label="Add User Group" />
                <SearchAutoComplete
                  name="Add User Group"
                  optionsData={isLoading ? [] : userGroups ?? []}
                  typeOnchange={(event, value) => {
                    if (formValue.division === "undefined") {
                      setisUserGroup(true);
                      setisUserGroupErrMsg("Please select division first");
                      return;
                    } else {
                      setisUserGroup(false);
                      setisUserGroupErrMsg("");

                      handleAllGroupSearch(event, formValue.division);
                    }
                  }}
                  isKeyValuePair={true}
                  isLoading={isLoading}
                  value={formValue.supervisor}
                  onChange={(e, value) => setUsersGroup(value)}
                  noOptionsTextMsg="No group"
                  autoCompClear={autoComp}
                />
              </Grid>
              <Grid
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className="smallAddBtn"
                mt={3.5}
                pl={2}
              >
                <Buttons
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={"Add"}
                  onClick={() => addUserGroupData()}
                  id="btnAddEquipment"
                  varientAddIconBlue={true}
                ></Buttons>
              </Grid>
              <Grid mt={4} container gap={1}>
                <UsersChip
                  isKeyValuePair={true}
                  userList={userGroupList}
                  handleDelete={(e) => deleteusersGroupData(e)}
                />
              </Grid>
            </Grid>

            <Grid container item mt={3}>
              <Grid lg={6} md={6} sm={6} xs={12}>
                {isContractor && (
                  <div className="errorMsg">{isContractorErrMsg}</div>
                )}
                <FormLabels label="Add Subcontractors" />
                <SearchAutoComplete
                  name="Add Subcontractors"
                  optionsData={allUsers?.users ?? []}
                  typeOnchange={(event, value = 1) => {
                    if (formValue.division === "undefined") {
                      setisContractor(true);
                      setisContractorErrMsg("Please select division first");
                      return;
                    } else {
                      setisContractor(false);
                      setisContractorErrMsg("");

                      handleAllUserSearch(
                        event,
                        USER_TYPE.DIVISION_CONTRACTOR,
                        formValue.division
                      );
                    }
                  }}
                  isKeyValuePair={true}
                  value={formValue.subContractor}
                  onChange={(e, value) => setContractorGroups(value)}
                  autoCompClear={autoCompContractor}
                />
              </Grid>

              <Grid
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className="smallAddBtn"
                mt={3.5}
                pl={2}
              >
                <Buttons
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={"Add"}
                  onClick={() => addContractorGroupsData()}
                  id="btnAddEquipment"
                  varientAddIconBlue={true}
                ></Buttons>
              </Grid>
              <Grid mt={3} container gap={1}>
                <UsersChip
                  isKeyValuePair={true}
                  userList={contractorGroupsList}
                  handleDelete={(e) => deleteContractorGroupsData(e)}
                />
              </Grid>
            </Grid>

            <Grid item container mt={3} spacing={2}>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <FormLabels label={"Start Date"} isRequired={true} />
                  <DatePicker
                    value={formValue.startDate}
                    onChangeHandler={(e) => {
                      setFormValue({ ...formValue, startDate: e });
                      setisstartDate(false);
                    }}
                    isError={isstartDate}
                    errorMsg={isstartDate ? isstartDateErrMsg : ""}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <FormLabels label={"Completion Date"} />
                  <DatePicker
                    value={formValue.completionDate}
                    maxDateEnable
                    minDate={formValue.startDate}
                    onChangeHandler={(e) => {
                      setFormValue({ ...formValue, completionDate: e });
                      setisCompletionDate(false);
                    }}
                    isError={isCompletionDate}
                    errorMsg={isCompletionDate ? isCompletionDateErrMsg : ""}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container mt={2}>
              <Grid item lg={12} md={12} xs={12}>
                <FormControl fullWidth>
                  <FormLabels label="Add Notes" />
                  <TextArea
                    placeholder={"Enter Here"}
                    value={formValue.notes}
                    onChange={(e) => {
                      setFormValue({
                        ...formValue,
                        notes: e.target.value,
                      });
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid item container lg={11} className="buttonsGrid" mt={3} mb={3}>
              <div>
                <BackBtn dataBack={dataBack} />
              </div>
              <Grid item lg={6} className="cancelSaveDiv">
                <Grid item lg={5}>
                  <Buttons
                    varientContained={true}
                    label={editMode ? "Update" : "Save"}
                    onClick={() => {
                      submitData();
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};

export default AddAsset;
