import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import "./NormalGrid.scss";
export default function NormalGrid({
  rowss,
  columnss,
  onSelectionModelChange,
  selectionModel,
  rowCount,
  getRowId,
  loading,
  onRowClick,
  isRowSelectable,
  components,
  onCellClick,
  checkboxSelection = true,
}) {
  return (
    <div className="normalTableBox">
      <DataGrid
        className="overrideDataGrid"
        getRowId={getRowId}
        checkboxSelection={checkboxSelection}
        rowCount={rowCount}
        rows={rowss}
        columns={columnss}
        selectionModel={selectionModel}
        onSelectionModelChange={(e) => onSelectionModelChange(e, rowss)}
        loading={loading}
        onRowClick={onRowClick}
        isRowSelectable={isRowSelectable}
        components={components}
        onCellClick={onCellClick}
      />
    </div>
  );
}
