import * as clientAction from "../actions/clientAction";
import { clientType } from "../constants/clientType";
import {
  getClientList,createClient,getClientDetsils,updateClient,deleteClient, searchAllClients,
} from "../../services/clientService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";


export function* getClientListSaga(params) {
  try {
    const { data, status, error } = yield call(getClientList, params);
    if (!data && status !== 200) throw apiError(error);
    const clientPage = clientType.CLIENT_PAGE;
    yield put(
      clientAction.getClientListSuccess({
        clientPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Client List Failed" };
    yield put(clientAction.getClientListFailure(data));
  }
}

export function* createClientSaga(params) {
  console.debug("createClientSaga");
  try {
    const { data, status, error } = yield call(createClient, params);
    if (!data && status !== 200) throw apiError(error);
    const clientPage = clientType.CLIENT_PAGE;
    yield put(
      clientAction.createClientSuccess({
        clientPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Create Client Failed" };
    yield put(clientAction.createClientFailure(data));
  }
}

export function* getClientDetailsSaga(datas) {
  console.debug("getClientDetailsSaga");
  try {
    const { data, status, error } = yield call(getClientDetsils, datas);
    if (!data && status !== 200) throw apiError(error);
    const clientPage = clientType.CLIENT_PAGE;
    yield put(
      clientAction.getClientDetsilsSuccess({
        clientPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Client Details Failed" };
    yield put(clientAction.getClientDetailsFailure(data));
  }
}

export function* updateClientSaga(params) {
  console.debug("updateClientSaga");
  try {
    const { data, status, error } = yield call(updateClient, params);
    if (!data && status !== 200) throw apiError(error);
    const clientPage = clientType.CLIENT_PAGE;
    yield put(
      clientAction.updateClientSuccess({
        clientPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Update Client Failed" };
    yield put(clientAction.updateClientFailure(data));
  }
}

function* deleteClientSaga(payload) {
  console.debug("deleteRoleSaga");
  try {
    const { data, status, error } = yield call(deleteClient, payload);
    if (!data && status !== 200) throw apiError(error);
    const clientPage = clientType.CLIENT_PAGE;
     yield put(
      clientAction.deleteClientSuccess({
        clientPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message ? error : { message: error || "Delete Client" };
    yield put(clientAction.deleteClientFailure(data));
  }
}

export function* allClientsSaga({ searchText }) {
  console.debug("allClientSaga");
  try {
    const { data, status, error } = yield call(searchAllClients, searchText);
    if (!data && status !== 200) throw apiError(error);
    const clientPage = clientType.CLIENT_PAGE;
    yield put(
      clientAction.searchAllClientsSuccess({
        clientPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Search All Client Failed" };
    yield put(clientAction.searchAllClientsFailure(data));
  }
}

const myClientSaga = [
  takeLatest(clientType.GET_CLIENT_LIST_PENDING, getClientListSaga),
  takeLatest(clientType.POST_CLIENT_PENDING, createClientSaga),
  takeLatest(clientType.GET_CLIENT_DETAILS_PENDING, getClientDetailsSaga),
  takeLatest(clientType.PUT_CLIENT_PENDING, updateClientSaga),
  takeLatest(clientType.DELETE_CLIENT_PENDING, deleteClientSaga),
  takeLatest(clientType.GET_ALL_CLIENT, allClientsSaga),



];

export default myClientSaga;
