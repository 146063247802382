import React, { useEffect } from "react";
import ViewClient from "../component/client/viewClient";
import { ADMIN_CLIENT_LIST } from "../../../constant/routeContant";
import * as clientAction from "../../../redux/actions/clientAction";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../library/common/Loader";
import { API_STATUS } from "../../../constant/constant";

export default function ViewClientContainer() {
  const dispatch = useDispatch();
  const location = useLocation();

  const Organisationid = location?.state?.Organisationid;

  const getClientDetsilsData = useSelector(
    (state) => state?.getClientDetsilsData?.data
  );

  const showLoader = useSelector(
    (state) => state?.getClientDetsilsData?.isLoading
  );
  const isSuccess = useSelector(
    (state) => state.getClientDetsilsData.status === API_STATUS.SUCCESS
  );

  useEffect(() => {
    dispatch(clientAction.getClientDetsils(Organisationid));
  }, [dispatch]);

  const dataBack = {
    title: "Back to Clients",
    route: ADMIN_CLIENT_LIST,
  };

  return (
    <>
      {showLoader && <Loader />}
      {isSuccess && (
        <ViewClient
          dataBack={dataBack}
          getClientDetsilsData={getClientDetsilsData}
          Organisationid={Organisationid}
        />
      )}
    </>
  );
}
