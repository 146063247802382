import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import ViewImages from "../../../incidents/view-incident-report/common/viewImages/viewImages";
import "./addClient.scss";

export default function ViewClient({
  getClientDetsilsData,
  dataBack,
  Organisationid,
}) {
  const data = getClientDetsilsData?.result;
  const imageSize = {
    size: "small",
  };
  const [image, setImage] = useState([]);
  const [platformTypes, setPlatformTypes] = useState({
    internal: false,
    external: false,
  });
  useEffect(() => {
    setImage(data?.logoUrl);
    if (data?.platform?.includes(1)) {
      setPlatformTypes((prevState) => ({ ...prevState, internal: true }));
    }
    if (data?.platform?.includes(2)) {
      setPlatformTypes((prevState) => ({ ...prevState, external: true }));
    }
  }, [data]);
  useEffect(() => {
    console.log(image);
  }, [image]);

  return (
    <>
      <div className="viewOverideClient">
        <BackBtn dataBack={dataBack} />
      </div>
      <div className="viewOverideContact">
        <div className="commonOveride">
          <Typography className="titleDetail">{data?.fullName}</Typography>
          <Box className="commoninfoWrap">
            <Box className="commoninfoBox">
              <Box className="commoninfoleft">
                <Typography className="title">Details:</Typography>
                <Box className="columns">
                  <Typography className="listView">
                    Client ID:
                    <span className="font-w400">{Organisationid}</span>
                  </Typography>
                  <Typography className="listView">
                    Industry Type:
                    <span className="font-w400">{data?.industryType}</span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Point Of Contact Name:
                    <span className="font-w400">{data?.pointOfContact}</span>
                  </Typography>
                  <Typography className="listView">
                    Client Short Name:
                    <span className="font-w400">{data?.shortName}</span>
                  </Typography>
                </Box>

                <Box className="columns">
                  <Typography className="listView">
                    No. of Employees:
                    <span className="font-w400">{data?.noOfEmployees}</span>
                  </Typography>
                  <Typography className="listView">
                    Preferred Language:
                    <span className="font-w400">
                      {" "}
                      {data?.preferredLanguageId === 1 ? "English" : "Spanish"}
                    </span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Address1:
                    <span className="font-w400">{data?.address1}</span>
                  </Typography>
                  <Typography className="listView">
                    Address2:
                    <span className="font-w400">{data?.address2}</span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    State:
                    <span className="font-w400">{data?.stateName}</span>
                  </Typography>
                  <Typography className="listView">
                    City:
                    <span className="font-w400">{data?.cityName}</span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Zip Code:
                    <span className="font-w400">{data?.zipCode}</span>
                  </Typography>
                  <Typography className="listView">
                    Phone Number:
                    <span className="font-w400">{data?.phoneNumber}</span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Email Id:
                    <span className="font-w400">{data?.email}</span>
                  </Typography>
                  <Typography className="listView">
                    Website:
                    <span className="font-w400">{data?.webSite}</span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView ">
                    Product:
                    <span className="font-w400">
                      {data?.product?.map((val, index) => {
                        return (
                          <spna key={index}>
                            {" "}
                            {(index ? ", " : " ") + val?.value}
                          </spna>
                        );
                      })}
                    </span>
                  </Typography>

                  <Typography className="listView">
                    Media Allowed For Inspections :
                    <span className="font-w400">
                      {data?.isMediaAllowed == true ? "Yes" : "No"}
                    </span>
                  </Typography>
                </Box>
                <Box>
                  <Typography className="listView inspectionPlatform">
                    <span className="blockView inspectionPlatformTypeTitle">
                      Inspection Platform:
                    </span>
                    <div className="inspectionPlatformType">
                      <span className="blockView">
                        <strong>Internal</strong>:{" "}
                        {platformTypes?.internal ? "Yes" : "No"}
                      </span>
                      <span className="blockView">
                        <strong>External</strong>:{" "}
                        {platformTypes?.external ? "Yes" : "No"}
                      </span>
                    </div>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    PDF Email Notification:
                    <span className="font-w400">
                      {data?.isInsCombinedPdf === true
                        ? "1 Report"
                        : "3 Reports"}
                    </span>
                  </Typography>
                </Box>
                <Box>
                  <Typography className="listView ">
                    Client Logo:
                    <span className="blockView">
                      <ViewImages
                        image={image?.length > 0 ? [image] : []}
                        imageSize={imageSize}
                      />
                    </span>
                    {/* )} */}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
}
