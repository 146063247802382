export const clientType = {
  GET_CLIENT_LIST_PENDING:
    "[GET_CLIENT_LIST][REQUEST] Get Client list requested",
  GET_CLIENT_LIST_FAILURE:
    "[GET_CONTRACTOR_LIST][RESPONSE] Get Client list Failed",
  GET_CLIENT_LIST_SUCCESS:
    "[GET_CONTRACTOR_LIST][RESPONSE] Get Client list Successfull",
  CLIENT_PAGE: "CLIENT_PAGE",

  POST_CLIENT_PENDING: "[CLIENT][REQUEST] Create Client",
  POST_CLIENT_FAILURE: "[CLIENT][RESPONSE]  Client  Creation Failed",
  POST_CLIENT_SUCCESS: "[CLIENT][RESPONSE] Client Created Successfull",

  PUT_CLIENT_PENDING: "[CLIENT][REQUEST] Update Client",
  PUT_CLIENT_FAILURE: "[CLIENT][RESPONSE] Client Updation Failed",
  PUT_CLIENT_SUCCESS: "[CLIENT][RESPONSE] Client Updation Successfull",

  GET_CLIENT_DETAILS_PENDING:
    "[CLIENT_DETAILS][REQUEST] Get Client Details Data",
  GET_CLIENT_DETAILS_SUCCESS:
    "[CLIENT_DETAILS][RESPONSE] Get Client Details Data Successfull ",
  GET_CLIENT_DETAILS_FAILURE:
    "[CLIENT_DETAILS][RESPONSE] Get Client Details Data Failed ",

  DELETE_CLIENT_PENDING: "[REQUEST] Delete Client",
  DELETE_CLIENT_SUCCESS: "[RESPONSE] Delete Client Successfull",
  DELETE_CLIENT_FAILURE: "[RESPONSE] Delete Client Failed",

  CLEAR_CLIENT: "[CLEAR_CLIENT][REQUEST] Clear Client",

  GET_ALL_CLIENT: "[REQUEST GET_ALL_CLIENT] Search All Client ",
  GET_ALL_CLIENT_SUCCESS:
    "[RESPONSE GET_ALL_CLIENT_SUCCESS] Seach All Client  Successfull",
  GET_ALL_CLIENT_FAILURE:
    "[RESPONSE GET_ALL_CLIENT_FAILURE] Seacrh All Client  Failed",
};
