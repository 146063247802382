import { getProjectDetailsType, projectType } from "../constants/projectType";
import { API_STATUS } from "../../constant/constant";

const initialState = {
  flow: "project",
  isError: false,
  isLoading: false,
};

export const projectListReducer = (state = initialState, action) => {
  switch (action.type) {
    case projectType.GET_PROJECT_LIST:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case projectType.GET_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case projectType.GET_PROJECT_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};

export const createProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case projectType.POST_CREATE_PROJECT:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case projectType.POST_CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case projectType.POST_CREATE_PROJECT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
      case projectType.CLEAR_PROJECT:
        return {
          ...state,
          isError: true,
          isLoading: false,
          data: "",
        };
    default:
      return state;
  }
};

export const deleteProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case projectType.DELETE_PROJECT:
        return {
          ...state,
          status: API_STATUS.PENDING,
          isError: false,
          isLoading: true,
        };
      case projectType.DELETE_PROJECT_SUCCESS:
        return {
          ...state,
          status: API_STATUS.SUCCESS,
          ...action.data,
          isError: false,
          isLoading: false,
        };
      case projectType.DELETE_PROJECT_FAILURE:
        return {
          ...state,
          status: API_STATUS.FAILURE,
          isError: true,
          isLoading: false,
          error: { ...action.error },
        };
        case projectType.CLEAR_PROJECT:
          return {
            ...state,
            isError: true,
            isLoading: false,
            data: "",
          };
      default:
        return state;
    }
  };
  
export const getProjectDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case getProjectDetailsType.GET_PROJECT_DETAILS:
      return {
        ...state,
        status: API_STATUS.PENDING,
        isError: false,
        isLoading: true,
      };
    case getProjectDetailsType.GET_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        ...action.data,
        isError: false,
        isLoading: false,
      };
    case getProjectDetailsType.GET_PROJECT_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        isError: true,
        isLoading: false,
        error: { ...action.error },
      };
    default:
      return state;
  }
};
  
  
  export const updateProjectReducer = (state = initialState, action) => {
    switch (action.type) {
      case projectType.POST_PROJECT_UPDATE:
        return {
          ...state,
          status: API_STATUS.PENDING,
          isError: false,
          isLoading: true,
        };
      case projectType.POST_PROJECT_UPDATE_SUCCESS:
        return {
          ...state,
          status: API_STATUS.SUCCESS,
          ...action.data,
          isError: false,
          isLoading: false,
        };
      case projectType.POST_PROJECT_UPDATE_FAILURE:
        return {
          ...state,
          status: API_STATUS.FAILURE,
          isError: true,
          isLoading: false,
          error: { ...action.error },
        };
        case projectType.CLEAR_PROJECT:
          return {
            ...state,
            isError: true,
            isLoading: false,
            data: "",
          };
      default:
        return state;
    }
  };
  