import React from "react";
import "../../../incidents/cases/addCaseList.scss";
import "./userForm.scss";
import Buttons from "../../../../library/custom/button/Button";
import { Grid } from "@mui/material";
import { InputField } from "../../../../library/custom/textBox/InputField";
import { NumberField } from "../../../../library/custom/numberField/numberField";
import FormControl from "@mui/material/FormControl";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import DatePicker from "../../../../library/custom/datePicker/DatePicker";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { dateValiadtor, getFormatedDate } from "../../../../utils/helper";
import { COUNTRY_CODE, ORG_ID, USER_ID } from "../../../../constant/constant";
import Checkbox from "../../../../library/checkbox/checkbox";
import MultiSelectBox from "../../../../library/custom/selectMenu/MultiSelectBox";

const UserConfigurationEdit = ({
  divisions,
  jobTitles,
  roles,
  updateUserOnboard,
  editMode,
  getUserConfigurationDetsilsData,
  onboardUserId,
  divisionSafety,
  roleStormWater,
  divisionStormWater,
  getDropDowndata,
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [jobTitle, setJobTitleName] = useState(null);
  const [date, setDate] = useState(null);
  const [division, setDivision] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [selectUserRole, setSelectUserRole] = useState([]);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [companyNameErro, setCompanyNameError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [divisionError, setDivisionError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [selectUserRoleError, setSelectUserRoleError] = useState(false);
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [checkBox, setcheckBox] = useState(false);
  const [dateErrMsg, setDateErrMsg] = useState("");
  const [serviceType, setServiceType] = useState([]);
  const [serviceTypeError, setServiceTypeError] = useState(false);
  const [stromWaterdivision, setStromWaterDivision] = useState("");
  const [stromWaterdivisionError, setStromWaterDivisionError] = useState(false);
  const [stromWaterRole, setStromWaterRole] = useState("");
  const [stormWaterRoleError, setStormWaterRoleError] = useState(false);

  console.log("stromWaterdivision", stromWaterdivision);
  console.log("stromWaterdivisionError", stromWaterdivisionError);

  console.log("division", division);
  console.log("divisionError", divisionError);

  const [phoneverify, setPhoneverify] = useState(false);
  const [emailverify, setEmailverify] = useState(false);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const serviceTypes = userInfo?.product;

  useEffect(() => {
    if (serviceTypes?.length === 1) {
      setServiceType(serviceTypes[0].key);
    }
  }, [serviceTypes]);

  useEffect(() => {
    const companyNames = localStorage.getItem("companyName");
    const orgNames = localStorage.getItem("orgName");
    if (orgNames) {
      setCompanyName(orgNames);
    } else {
      setCompanyName(companyNames);
    }
  }, []);

  const onChangeServiceType = (event) => {
    setServiceTypeError(false);
    const {
      target: { value },
    } = event;
    setServiceType(typeof value === "string" ? value.split(",") : value);
  };

  const findValueMultipleSelectServiveType = (
    arrrayofObject,
    selectedArray
  ) => {
    if (serviceTypes?.length > 1) {
      let valueArray = [];
      arrrayofObject.forEach((data) => {
        if (selectedArray?.indexOf(data.value) >= 0) {
          valueArray.push(data.key);
        }
      });
      return valueArray;
    }
  };

  useEffect(() => {
    if (
      (editMode && serviceType?.length === 2) ||
      serviceType?.length === 3 ||
      serviceType?.length === 4
    ) {
      getDropDowndata(1);
      setTimeout(() => {
        getDropDowndata(2);
      }, 2000);
    } else {
      if (serviceType?.length === 2 && serviceType[1] === "Safety") {
        getDropDowndata(1);
      } else if (serviceType?.length === 2 && serviceType[1] === "Stormwater") {
        getDropDowndata(2);
      } else if (serviceType?.length === 1 && serviceType[0] === "Safety") {
        getDropDowndata(1);
      } else if (serviceType?.length === 1 && serviceType[0] === "Stormwater") {
        getDropDowndata(2);
      } else if (serviceType?.length === 6) {
        getDropDowndata(1);
      } else if (serviceType?.length === 10) {
        getDropDowndata(2);
      }
    }
  }, [serviceType]);

  useEffect(() => {
    if (getUserConfigurationDetsilsData) {
      setFirstName(getUserConfigurationDetsilsData?.data?.firstName);
      setLastName(getUserConfigurationDetsilsData?.data?.lastName);
      setDivision(
        getUserConfigurationDetsilsData?.data?.safetyDivision?.map(
          (item) => item.key
        )
      );
      setStromWaterDivision(
        getUserConfigurationDetsilsData?.data?.stormDivision?.map(
          (item) => item.key
        )
      );
      setEmail(getUserConfigurationDetsilsData?.data?.email);
      //   setCompanyName(getUserConfigurationDetsilsData?.companyname);
      const phone = getUserConfigurationDetsilsData?.data?.phoneNumber;
      setPhone(
        phone && phone > 10
          ? phone.substring(phone.length - 10, phone.length)
          : phone
      );
      setSelectUserRole(
        getUserConfigurationDetsilsData?.data?.safetyRoles?.map(
          (item) => item.key
        )
      );
      setStromWaterRole(
        getUserConfigurationDetsilsData?.data?.stormRoles?.map(
          (item) => item.key
        )
      );

      setServiceType(
        getUserConfigurationDetsilsData?.data?.product?.map(
          (item) => item.value
        )
      );
      setDate(getUserConfigurationDetsilsData?.data?.startDate);
      setJobTitleName(getUserConfigurationDetsilsData?.data?.jobTitleId);
      setcheckBox(!getUserConfigurationDetsilsData?.data?.startDate);
    }
  }, [getUserConfigurationDetsilsData]);

  const history = useNavigate();

  const onChangeStromWaterDivision = (e) => {
    setStromWaterDivision(e.target.value);
  };

  const onChangeStormWaterUserRole = (e) => {
    setStromWaterRole(e.target.value);
  };

  const firstNameHandler = (e) => {
    setFirstName(e.target.value);
  };

  const lastNameHandler = (e) => {
    setLastName(e.target.value);
  };

  const onChangeJobTitle = (e) => {
    setJobTitleName(e.target.value);
  };

  const onchangedatePicker = (e) => {
    setDate(e);
    setDateErrMsg(false);
  };

  const onChangeDivision = (e) => {
    setDivision(e.target.value);
  };
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    setEmail(e.target.value);
    const isValidEmail = /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (e.target?.value && e.target.value.match(isValidEmail)) {
      setEmailverify(false);
    } else {
      setEmailverify(true);
    }

    setEmail(e.target.value);
    if (e.target.value == "") {
      setEmailverify(false);
    }
  };
  const onChangeSelectUserRole = (e) => {
    setSelectUserRole(e.target.value);
  };
  const userIds = localStorage.getItem("userId");
  const onClickASaveUser = (e) => {
    const selectedServiceType = findValueMultipleSelectServiveType(
      serviceTypes,
      serviceType
    );

    let isFieldEmpty = false;
    if (!firstName) {
      setFirstNameError(true);
      isFieldEmpty = true;
    }
    if (!lastName) {
      setLastNameError(true);
      isFieldEmpty = true;
    }
    if (!email) {
      setEmailError(true);
      isFieldEmpty = true;
    }

    if (!phone) {
      setPhoneError(true);
      isFieldEmpty = true;
    }
    if (phone?.length != 10) {
      setPhoneverify(true);
      isFieldEmpty = true;
    }
    if (!date && checkBox === false) {
      setDateError(true);
      isFieldEmpty = true;
      return;
    }
    if (date) {
      const validDate = dateValiadtor(getFormatedDate(date));
      if (validDate === false) {
        setDateErrMsg(true);
        isFieldEmpty = true;
        return;
      } else if (validDate === "Valid date") {
        setDateErrMsg(false);
        // isFieldEmpty = true;
      }
    }
    if (serviceType?.includes("Safety")) {
      if (division?.length === 0) {
        setDivisionError(true);
        isFieldEmpty = true;
      }
    }
    if (serviceType?.includes("Safety")) {
      if (selectUserRole.length === 0) {
        if (
          editMode &&
          getUserConfigurationDetsilsData?.data?.safetyRole?.length > 0
        ) {
          setSelectUserRoleError(false);
          isFieldEmpty = false;
        } else {
          setSelectUserRoleError(true);
          isFieldEmpty = true;
        }
      }
    }

    if (serviceType?.includes("Stormwater")) {
      if (stromWaterdivision?.length === 0) {
        setStromWaterDivisionError(true);
        isFieldEmpty = true;
      }
    }
    if (serviceType?.includes("Stormwater")) {
      if (stromWaterRole.length === 0) {
        if (
          editMode &&
          getUserConfigurationDetsilsData?.data?.stormRole?.length > 0
        ) {
          setStormWaterRoleError(false);
          isFieldEmpty = false;
        } else {
          setStormWaterRoleError(true);
          isFieldEmpty = true;
        }
      }
    }
    if (!serviceType || serviceType?.length === 0) {
      setServiceTypeError(true);
      isFieldEmpty = true;
    }
    if (!isFieldEmpty) {
      let findData = {};
      if (!editMode) {
        findData = roles.find((role) => role.key === selectUserRole);
      }
      let sendData = {
        users: [
          {
            firstname: firstName,
            lastname: lastName,
            email: email,
            isemailverified: false,
            companyname: companyName,
            phonenumber: phone,
            isphonenumberverified: true,
            jobTitleId: jobTitle,
            startDate: checkBox ? null : getFormatedDate(date),
            divisionId: division,
            role: findData.key,
            status: true,
            organisationId: ORG_ID(),
            isdCode: COUNTRY_CODE,
            productIds: selectedServiceType
              ? selectedServiceType
              : [serviceType],
          },
        ],
        createdbyuser: userIds,
        createdbyapp: "0",
      };
      let sendDataEdit = {
        onboardUserId: onboardUserId,
        firstName: firstName,
        lastName: lastName,
        email: email,
        organisationId: ORG_ID(),
        isEmailVerified: getUserConfigurationDetsilsData?.data?.isEmailVerified,
        phoneNumber: phone,
        isPhoneNumberVerified:
          getUserConfigurationDetsilsData?.data?.isPhoneNumberVerified,
        jobTitleId: jobTitle,
        startDate: checkBox ? null : getFormatedDate(date),
        safetyDivisionId: division ? parseInt(division?.toString()) : null,
        safetyRoleId:
          editMode && Array.isArray(selectUserRole) ? null : selectUserRole,
        stormRoleId:
          editMode && Array.isArray(stromWaterRole) ? null : stromWaterRole,
        stormDivisionId: stromWaterdivision
          ? parseInt(stromWaterdivision?.toString())
          : null,
        preferredLng: 0,
        companyName: companyName,
        isdCode: COUNTRY_CODE,
        status: 0,
        isDeleted: getUserConfigurationDetsilsData?.data?.isDeleted,
        createdBy: USER_ID(),
        modifiedBy: USER_ID(),
        productIds: selectedServiceType ? selectedServiceType : [serviceType],
      };
      updateUserOnboard(editMode ? sendDataEdit : sendData);
    }
  };

  const checkBoxHandler = () => {
    setcheckBox(!checkBox);
    if (checkBox) {
      setDateError(true);
    } else {
      setDateError(false);
    }
  };

  return (
    <Grid container mt={4}>
      <Grid item lg={9} xs={12} sm={12} md={9}>
        <Grid container>
          <Grid item lg={6} xs={12} sm={12} md={6} textAlign="left">
            <div className="heading_style">Edit Users</div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12} xs={12} sm={12} md={12}>
        <div>
          <Grid container item md={9} xs={9} className="addShadowBox">
            <Grid container mt={2} spacing={3}>
              <Grid item lg={6} xs={12} sm={12} md={4}>
                <FormControl fullWidth={true}>
                  <InputField
                    disabled={false}
                    isRequired={true}
                    type="text"
                    label={"First Name"}
                    value={firstName}
                    placeholder="Enter first name"
                    onChange={(e) => firstNameHandler(e)}
                  />
                </FormControl>
                {firstNameError && !firstName && (
                  <div className="errorMsg">
                    Please enter this required field
                  </div>
                )}
              </Grid>
              <Grid item lg={6} xs={12} sm={12} md={4}>
                <FormControl fullWidth={true}>
                  <InputField
                    disabled={false}
                    isRequired={true}
                    type="text"
                    label={"Last Name"}
                    value={lastName}
                    placeholder="Enter last name"
                    onChange={(e) => lastNameHandler(e)}
                  />
                </FormControl>
                {lastNameError && !lastName && (
                  <div className="errorMsg">
                    Please enter this required field
                  </div>
                )}
              </Grid>
            </Grid>

            <Grid container mt={1} spacing={3}>
              <Grid item lg={6} xs={12} sm={12} md={4}>
                <FormControl fullWidth={true}>
                  <InputField
                    disabled={false}
                    isRequired={true}
                    type="text"
                    label={"Email"}
                    value={email}
                    placeholder="Enter Email"
                    onChange={(e) => onChangeEmail(e)}
                  />
                </FormControl>
                {emailError && !email && (
                  <div className="errorMsg">
                    Please enter this required field
                  </div>
                )}
                {emailverify && (
                  <div className="errorMsg">
                    Please enter a valid Email address
                  </div>
                )}
              </Grid>
              <Grid item lg={6} xs={12} sm={12} md={4}>
                <FormControl fullWidth={true}>
                  <NumberField
                    disabled={false}
                    isRequired={true}
                    type="text"
                    label={"Phone"}
                    maxLength="10"
                    value={phone}
                    placeholder={`Enter Phone number without ${COUNTRY_CODE}`}
                    onChange={(value) =>
                      value.length <= 10 ? setPhone(value) : phone
                    }
                  />
                </FormControl>
                {phoneError && !phone && (
                  <div className="errorMsg">
                    Please enter this required field
                  </div>
                )}
                {phoneverify && phone?.length != 10 && (
                  <div className="errorMsg">
                    Please enter 10 digit valid Phone Number
                  </div>
                )}
              </Grid>
            </Grid>

            <Grid item container mt={0} spacing={3}>
              <Grid item lg={6} xs={12} sm={12} md={4}>
                <FormControl fullWidth>
                  <FormLabels label="Company" />
                  <InputField
                    disabled={true}
                    isRequired={true}
                    type="text"
                    value={companyName}
                  />
                </FormControl>
                {companyNameErro && !companyName && (
                  <div className="errorMsg">
                    Please enter this required field
                  </div>
                )}
              </Grid>
              <Grid item lg={6} xs={12} sm={12} md={4}>
                <FormControl fullWidth>
                  <FormLabels label="Job Title" isRequired={false} />
                  <SelectMenu
                    listData={jobTitles}
                    value={jobTitle}
                    onchangehandler={(e) => onChangeJobTitle(e)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container mt={0} spacing={3}>
              <Grid item lg={6} xs={12} sm={12} md={4}>
                <FormControl fullWidth>
                  <FormLabels label={"Hire Date"} isRequired={true} />
                  <DatePicker
                    value={date}
                    disabled={checkBox}
                    onChangeHandler={(e) => onchangedatePicker(e)}
                  />
                </FormControl>
                {dateError && !date && (
                  <div className="errorMsg">Please select requsired field</div>
                )}
                {dateErrMsg && date != null && (
                  <div className="errorMsg">Invalid Date</div>
                )}
              </Grid>
              {serviceTypes.length > 1 && (
                <Grid item lg={6} xs={12} sm={12} md={6} pl={2}>
                  <FormControl fullWidth>
                    <FormLabels label="Service Type" isRequired={true} />

                    <MultiSelectBox
                      placeholder={"Please Select"}
                      listData={serviceTypes}
                      onChange={(e) => onChangeServiceType(e)}
                      value={serviceType || []}
                      isError={serviceTypeError}
                      errorMessage="Selection is required for this field"
                    />
                  </FormControl>
                </Grid>
              )}
              {serviceTypes.length === 1 && (
                <Grid item lg={6} xs={12} sm={12} md={6} pl={2}>
                  <FormControl fullWidth>
                    <FormLabels label="Service Type" isRequired={true} />
                    {serviceTypes?.map((value, index) => {
                      return (
                        <div className="serviveTypeStyle" key={value?.key}>
                          {" "}
                          {value?.value}
                        </div>
                      );
                    })}
                  </FormControl>
                </Grid>
              )}
            </Grid>

            <Grid item container mt={0} spacing={1}>
              <Grid item lg={5} xs={12} sm={12} md={4}>
                <div className="typeTxt">
                  <Checkbox
                    checked={checkBox}
                    onChange={() => checkBoxHandler()}
                    label={"Did Not Provide"}
                  />
                </div>
              </Grid>
            </Grid>

            {serviceType == 1 ||
              (serviceType?.includes("Safety") && (
                <>
                  <Grid container>
                    <Grid
                      item
                      lg={6}
                      xs={12}
                      sm={12}
                      md={6}
                      mt={4}
                      textAlign="left"
                    >
                      <div className="heading_style">Safety</div>
                    </Grid>
                  </Grid>

                  <Grid item container mt={0} spacing={3}>
                    <Grid item lg={6} xs={12} sm={12} md={4}>
                      <FormControl fullWidth>
                        <FormLabels label="Division" isRequired={true} />
                        <SelectMenu
                          listData={divisionSafety}
                          value={division}
                          onchangehandler={(e) => onChangeDivision(e)}
                        />
                      </FormControl>
                      {divisionError && division?.length === 0 && (
                        <div className="errorMsg">
                          Please enter this required field
                        </div>
                      )}
                    </Grid>
                    <Grid item lg={6} xs={12} sm={12} md={4}>
                      <FormControl fullWidth>
                        <FormLabels
                          label={
                            editMode &&
                            getUserConfigurationDetsilsData?.data?.safetyRoles
                              ?.length > 0
                              ? "Assigned User Role"
                              : "Select User Role"
                          }
                          isRequired={true}
                        />
                        {editMode &&
                        getUserConfigurationDetsilsData?.data?.safetyRoles
                          ?.length > 0 ? (
                          <>
                            {getUserConfigurationDetsilsData?.data?.safetyRoles?.map(
                              (value, index) => {
                                return (
                                  <div key={value?.key}>
                                    {value?.value}{" "}
                                    {index + 1 ==
                                    getUserConfigurationDetsilsData?.data
                                      ?.safetyRoles.length
                                      ? ""
                                      : ","}
                                  </div>
                                );
                              }
                            )}
                          </>
                        ) : (
                          <>
                            <SelectMenu
                              placeholder="Please Select"
                              listData={roles}
                              value={selectUserRole}
                              onchangehandler={(e) => onChangeSelectUserRole(e)}
                            />
                            {selectUserRoleError &&
                              selectUserRole?.length === 0 && (
                                <div className="errorMsg">
                                  Please enter this required field
                                </div>
                              )}
                          </>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </>
              ))}

            {serviceType?.includes("Stormwater") && (
              <>
                <Grid container>
                  <Grid
                    item
                    lg={6}
                    xs={12}
                    sm={12}
                    md={6}
                    mt={4}
                    textAlign="left"
                  >
                    <div className="heading_style">Stormwater</div>
                  </Grid>
                </Grid>

                <Grid item container mt={0} spacing={3}>
                  <Grid item lg={6} xs={12} sm={12} md={4}>
                    <FormControl fullWidth>
                      <FormLabels label="Division" isRequired={true} />
                      <SelectMenu
                        placeholder="Please Select"
                        listData={divisionStormWater}
                        value={stromWaterdivision}
                        onchangehandler={(e) => onChangeStromWaterDivision(e)}
                      />
                    </FormControl>
                    {stromWaterdivisionError &&
                      stromWaterdivision?.length === 0 && (
                        <div className="errorMsg">
                          Please enter this required field
                        </div>
                      )}
                  </Grid>

                  <Grid item lg={6} xs={12} sm={12} md={4}>
                    <FormControl fullWidth>
                      <FormLabels
                        label={
                          editMode &&
                          getUserConfigurationDetsilsData?.data?.stormRoles
                            ?.length > 0
                            ? "Assigned User Role"
                            : "Select User Role"
                        }
                        isRequired={true}
                      />
                      {editMode &&
                      getUserConfigurationDetsilsData?.data?.stormRoles
                        ?.length > 0 ? (
                        <>
                          {" "}
                          {getUserConfigurationDetsilsData?.data?.stormRoles?.map(
                            (value, index) => {
                              return (
                                <div key={value?.key}>
                                  {value?.value}{" "}
                                  {index + 1 ==
                                  getUserConfigurationDetsilsData?.data
                                    ?.stormRoles.length
                                    ? ""
                                    : ","}
                                </div>
                              );
                            }
                          )}
                        </>
                      ) : (
                        <>
                          <SelectMenu
                            placeholder="Please Select"
                            listData={roleStormWater}
                            value={stromWaterRole}
                            onchangehandler={(e) =>
                              onChangeStormWaterUserRole(e)
                            }
                          />

                          {stormWaterRoleError &&
                            stromWaterRole?.length === 0 && (
                              <div className="errorMsg">
                                Please enter this required field
                              </div>
                            )}
                        </>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            )}

            <Grid container mb={10} mt={1} spacing={3}>
              <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left"></Grid>
              <Grid item lg={6} xs={12} sm={12} md={4}>
                <div className="adduser_btn">
                  <div
                    className="cancle_heading_style"
                    onClick={() => history(-1)}
                  >
                    Cancel
                  </div>
                  <Buttons
                    aria-describedby="dd"
                    variant="contained"
                    type="button"
                    name="btn"
                    label={editMode ? "save" : "save"}
                    varientContained={true}
                    onClick={(e) => onClickASaveUser(e)}
                  ></Buttons>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};
export default UserConfigurationEdit;
