import React, { useState } from "react";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import SearchIcon from "@mui/icons-material/Search";
import {
  LOGIN_ORG_ID,
  ORG_ID,
  PAGE_SIZE,
  ROW_PER_PAGE_SIZE,
  USER_ID,
} from "../../../../constant/constant";
import TableRowAction from "../../../../library/custom/gridTable/TableRowAction";
import { TableLoader } from "../../../../library/common/Loader";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import NORow from "../../../../library/custom/noRow/noRow";
import { useNavigate } from "react-router-dom";
import { ADMIN_ADD_PROJECT } from "../../../../constant/routeContant";
import DialogBox from "../../../../library/common/DialogBox";
import { Grid } from "@mui/material";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import FormControl from "@mui/material/FormControl";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const ProjectTable = ({
  projectsListData,
  getProjectListData,
  filterShow,
  deleteProject,
  isProjectsLoading,
  isProfile = false,
  divisionDropdown,
  isDivisionDropdown = true,
}) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  
  const [openModal, setOpenModal] = useState(false);
  const [division, setDivision] = useState(
    divisionDropdown ? divisionDropdown[0]?.key : 0
  );
  const [selectedRow, setSelectedRow] = useState("");

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      divisionId: division === 1 ? 0 : division,
      page: page,
      pageSize: pageSize,
      userId: isProfile ? parseInt(USER_ID()) : 0,
      search: searchText ? searchText : "",
    };
    getProjectListData(data);
  };
  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      divisionId: division === 1 ? 0 : division,
      pageSize: pageSize,
      userId: isProfile ? parseInt(USER_ID()) : 0,
      search: search,
      page: newPage + 1,
    };
    getProjectListData(data);
  };
  
  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    const data = {
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      divisionId: division === 1 ? 0 : division,
      
      pageSize: newPazeSize,
      userId: isProfile ? parseInt(USER_ID()) : 0,
      search: search,
      page: page,
    };
    getProjectListData(data);
  };
  const handleEditOnclick = (rows) => {
    navigate(ADMIN_ADD_PROJECT, {
      state: { id: rows.id, editMode: true },
    });
  };

  const onChangeDivision = (e) => {
    setDivision(e.target.value);
    setSearch("");
    const data = {
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      divisionId: e.target.value === 1 ? 0 : e.target.value,
      page: 1,
      pageSize: pageSize,
      search: "",
      userId: isProfile ? parseInt(USER_ID()) : 0,
    };
    getProjectListData(data);
    setPage(1);
  };

  const deletehandle = () => {
    setOpenModal(false);
    deleteProject(selectedRow);
  };

  const deleteProjectHandle = (rows) => {
    setOpenModal(true);
    setSelectedRow(rows);
  };
  const columns = [
    { field: "projectName", headerName: "Project Name", flex: 2.5 },
    { field: "id", headerName: "Project ID", flex: 1 },
    { field: "divisionName", headerName: "Division", flex: 1 },
    { field: "location", headerName: "Location", flex: 1 },
    { field: "startDate", headerName: "Start Date", flex: 1.5 },
    {
      field: "icon",
      renderHeader: () =>
        filterShow ? (
          <SettingTable
            noOfRecords={projectsListData?.itemsCount ?? 0}
            numberOfItemsPerPage={pageSize}
            incidentLists={projectsListData?.projectData ?? []}
            fileNameXL="projectList"
          />
        ) : (
          ""
        ),
      flex: 1,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return filterShow ? (
          <TableRowAction
            rows={rows}
            deleted={checkPrivileges([70, 12])}
            edit={checkPrivileges([70, 71, 12])}
            editOnClick={() => handleEditOnclick(rows)}
            deleteOnClick={() => deleteProjectHandle(rows.id)}
            deleteLabel="Delete Project"
            editLabel="Edit Project"
          />
        ) : (
          ""
        );
      },
    },
  ];
  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          {openModal && (
            <DialogBox
              open={openModal}
              rows={[]}
              header={"Confirm Deletion"}
              deletehandle={deletehandle}
              handleCloseModal={() => setOpenModal(false)}
              content={"Are you sure you want to delete this Project?"}
              view
            />
          )}
        </div>
        <div className="grid_main_body divisionWithsearch">
          <Grid container>
            <Grid item lg={6} xs={12} sm={6} md={6} mr={2} pt={3}>
              <div className="filter_of_caselist">
                <div className="searchcaseid">
                  <label htmlFor="search">
                    <span>
                      <SearchIcon />
                    </span>
                    <div>
                      <input
                        id="search"
                        placeholder="Search"
                        type="text"
                        value={search}
                        className=""
                        onChange={handleautoSearch}
                        autoComplete="off"
                      />
                    </div>
                  </label>
                </div>
              </div>
            </Grid>
            {isDivisionDropdown && (
              <Grid item lg={4} xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <FormLabels label="Select Division" isRequired={false} />
                  <SelectMenu
                    disabledDefault={true}
                    listData={divisionDropdown}
                    value={division === 0 ? 1 : division}
                    onchangehandler={(e) => onChangeDivision(e)}
                    isgroupplaceholder={true}
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>

          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <GridTable
                getRowId={(r) => r.id}
                columns={columns}
                rows={projectsListData?.projectData ?? []}
                rowCount={projectsListData?.itemsCount ?? 0}
                loading={isProjectsLoading}
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
                // rowsPerPageOptions={[10]}
                page={page}
                pagination
                pageSize={pageSize}
                onPageChange={handlePagignation}
                
                rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                
                onPageSizeChange={handlePageSizeChange}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProjectTable;
