import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import "./BulkUpload.scss";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import Buttons from "../../../library/custom/button/Button";
import ViewcCasetable from "../../../library/custom/gridTable/GridTable";
import { TableLoader } from "../../../library/common/Loader";
import NORow from "../../../library/custom/noRow/noRow";
import "../formDropBox/FormDropBox.scss";
import downloadIcon from "../../../../src/assets/downloadIcon.svg";
import {
  DOCUMENT_UPLOAD_MAX_NUM,
  DOCUMENT_UPLOAD_MAX_SIZE,
} from "../../../constant/constant";

import { randomStingByLength } from "../../../utils/helper";
import DownloadCsv from "../../../feature/download_file/DownloadCsv";
import { ToastContainer, toast } from "react-toastify";
const BulkUpload = ({
  id,
  label,
  paraText1,
  paraText2,
  paraText3,
  templateUrl,
  templateText,
  acceptedFileType = { "application/vnd.ms-excel": [".xlsx"] },
  handleFileChanges,
  pocFirstNameKey,
  pocLastNameKey,
  pocPhoneKey,
  pocFirstNamelabel,
  pocLastNamelabel,
  pocPhonelabel,
  isCustomColoumns,
  gridColoumns,
  mapBulkError = () => {},
}) => {
  const dowloadCsv = useRef();
  const [filedData, setFilesData] = useState(null);
  const [incorrectEnteriesList, setincorrectEnteriesList] = useState([]);

  const handeleUpload = (file) => {
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file[0]);
    fileReader.onload = (e) => {
      let arrayBuffer = fileReader.result;
      let data = new Uint8Array(arrayBuffer);
      let arr = [];
      for (let i = 0; i !== data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      let bstr = arr.join("");
      let workbook = XLSX.read(bstr, { type: "binary" });
      let first_sheet_name = workbook.SheetNames[0];
      let worksheet = workbook.Sheets[first_sheet_name];
      let arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: false });
      const users = arraylist.map((x, index) => {
        x.id =
          Date.now() + Math.floor(Math.random() * 100) + randomStingByLength(5);
        return x;
      });
      const inCorrectEnteries = mapBulkError(users);
      const correctEnteries = users.filter(
        (i) => inCorrectEnteries?.indexOf(i) === -1
      );
      setincorrectEnteriesList(inCorrectEnteries);
      setFilesData(correctEnteries);
    };
  };
  useEffect(() => {
    if (incorrectEnteriesList?.length > 0) {
      dowloadCsv?.current?.link?.click();
      toast.info(
        "Sheet has been downloaded in which mandatory fields are missing",
        { progressStyle: { background: "red" } }
      );
    }
  }, [incorrectEnteriesList]);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      return;
    }
    handeleUpload(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileType,
    maxFiles: DOCUMENT_UPLOAD_MAX_NUM,
    maxSize: DOCUMENT_UPLOAD_MAX_SIZE,
    multiple: false,
  });

  const deleteIncidents = (rows) => {
    const filterData = filedData.filter((list) => list.id !== rows.id);
    setFilesData(filterData);
  };

  const columns = [
    {
      field: "",
      width: 60,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return <div onClick={() => deleteIncidents(rows?.row)}>X</div>;
      },
    },
  ];
  const Grid_Columns = isCustomColoumns
    ? gridColoumns.concat(columns)
    : [
        { field: pocFirstNameKey, headerName: pocFirstNamelabel, width: 200 },
        { field: pocLastNameKey, headerName: pocLastNamelabel, flex: 2 },
        { field: pocPhoneKey, headerName: pocPhonelabel, flex: 2 },

        {
          field: "",
          width: 60,
          sortable: false,
          disableClickEventBubbling: true,
          renderCell: (rows) => {
            return <div onClick={() => deleteIncidents(rows?.row)}>X</div>;
          },
        },
      ];
  const uploadUsers = () => {
    handleFileChanges(filedData);
  };

  return (
    <>
      <div>
        <ToastContainer />
      </div>
      <DownloadCsv
        ref={dowloadCsv}
        data={incorrectEnteriesList ?? []}
        filename={"Incorrect Enteries List"}
      />
      <div className="overideFormDropBox" key={id}>
        <div className="dropBoxTitle">{label}</div>
        <div className="dropbox" {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <div>
              <p className="dropText">{paraText1}</p>
            </div>
          ) : (
            <div>
              <CloudUploadOutlinedIcon className="clousIcon" />
              <p className="browseText">
                {paraText2} <span>{paraText3}</span>
              </p>
            </div>
          )}
        </div>
        <div className="DownloadText">
          <a href={templateUrl} className="downLoadTemplate" target="_balnk">
            {" "}
            <span>
              <img
                src={downloadIcon}
                className="DownLoadLogo"
                alt="DownLoad Logo"
              />
            </span>
            {templateText}
          </a>
        </div>
      </div>
      {filedData && (
        <div className="desc_box row_uniq">
          <div className="table_style_ui">
            <ViewcCasetable
              getRowId={(r) => r.id}
              rows={filedData || []}
              rowCount={filedData?.length}
              columns={Grid_Columns}
              loading={false}
              components={{
                LoadingOverlay: TableLoader,
                NoRowsOverlay: NORow,
              }}
              checkboxSelection={false}
              rowsPerPageOptions={[10, 20, 30]}
              backIconButtonProps={{ disabled: false }}
              disableSelectionOnClick
            />
          </div>
        </div>
      )}
      {filedData && (
        <div className="btnMt">
          <Buttons
            className="btnstyles"
            aria-describedby="dd"
            variant="contained"
            type="button"
            name="btn"
            label={"Save"}
            varientContained={true}
            onClick={() => uploadUsers()}
          ></Buttons>
        </div>
      )}
    </>
  );
};

export default BulkUpload;
